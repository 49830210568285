<template>
    <button class="service">
        <img :src="info.logo" :alt="info.name" class="serviceLogo" />
        <div class="serviceContent">
            <p class="serviceTitle">{{ info.name }}</p>
            <p class="serviceTime" v-if="startDate">
                {{ startDate }} <br />
                {{ startTime }}
            </p>
        </div>
        <div class="serviceMarkers">
            <svg
                v-if="info.start && new Date() > new Date(info.start)"
                class="playMarker"
                width="21px"
                height="21px"
                viewBox="0 0 21 21"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
            >
                <g
                    id="Page-1"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                >
                    <path
                        d="M5.98848884,16.1142578 C6.26192634,16.1142578 6.49434822,16.0048828 6.76778572,15.8476562 L14.7384888,11.2402344 C15.3058717,10.9052734 15.5041138,10.6865234 15.5041138,10.3242188 C15.5041138,9.96191406 15.3058717,9.74316406 14.7384888,9.41503906 L6.76778572,4.80078125 C6.49434822,4.64355469 6.26192634,4.54101562 5.98848884,4.54101562 C5.48262947,4.54101562 5.16817634,4.92382812 5.16817634,5.51855469 L5.16817634,15.1298828 C5.16817634,15.7246094 5.48262947,16.1142578 5.98848884,16.1142578 Z"
                        id="􀊄"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                    ></path>
                </g>
            </svg>
        </div>
    </button>
</template>

<script>
export default {
    name: 'EpgListItem',
    props: {
        info: Object
    },
    data() {
        return {
            startDate: '',
            startTime: '',
            disabled: new Date() < new Date(this.info.start) ? true : false
        };
    },
    mounted() {
        if (this.info.start) {
            let tmpStart = new Date(this.info.start);
            this.startDate = `${this.twoDigit(
                tmpStart.getDate()
            )}-${this.twoDigit(
                tmpStart.getMonth() + 1
            )}-${tmpStart.getFullYear()}`;

            this.startTime = `${this.twoDigit(
                tmpStart.getHours()
            )}:${this.twoDigit(tmpStart.getMinutes())}`;
        }
    },
    methods: {
        twoDigit(num) {
            return num < 10 ? '0' + num : num;
        }
    }
};
</script>

<style lang="scss" scoped>
.service {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: white;
    transition: background 0.2s ease;
    &:disabled {
        .serviceLogo {
            filter: grayscale(100%);
        }
    }
    &:hover {
        & > .serviceContent {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
        }
        & > img.serviceLogo {
            filter: blur(4px);
            -webkit-filter: blur(4px);
        }
        &:disabled {
            & > img.serviceLogo {
                filter: blur(4px) grayscale(100%);
                -webkit-filter: blur(4px) grayscale(100%);
            }
        }
        background: darken($color: white, $amount: 20%);
    }
    .serviceContent {
        position: absolute;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        color: white;
        transition: 0.2s ease;
        font-weight: bold;
        transform: translateY(5px);
        text-align: center;
        .serviceTitle {
            font-weight: 900;
            font-size: 16px;
        }
        p {
            font-size: 13px;
            font-weight: 900;
        }
    }
    .serviceMarkers {
        position: absolute;
        z-index: 3;
        bottom: 5px;
        right: 5px;
        svg {
            height: 20px;
            width: 20px;
            path {
                fill: grey;
            }
        }
        .playMarker {
            path {
                fill: rgb(var(--themeColor));
            }
        }
        .recordMarker.recording {
            path {
                fill: red;
            }
        }
    }
    img.serviceLogo {
        border-radius: 10px;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: 0.3s ease;
        object-fit: contain;
    }
}
</style>
