<template>
    <form class="searchForm" @submit="(e) => e.preventDefault()">
        <div class="resetSearch" @click.prevent="resetSearch">
            <svg
                id="glyphicons-basic"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
            >
                <path
                    id="restart"
                    d="M9.308,22.022a.49776.49776,0,0,1-.00878.689L7.88641,24.12549a.50735.50735,0,0,1-.7276-.00781,11.97277,11.97277,0,0,1-1.59906-2.20093.50781.50781,0,0,1,.21637-.69483l1.7804-.90942a.498.498,0,0,1,.65863.20435A8.98118,8.98118,0,0,0,9.308,22.022ZM7.0462,16.94116A8.9539,8.9539,0,0,1,7.04541,15.082a.49775.49775,0,0,0-.4126-.55224L4.658,14.21826a.50762.50762,0,0,0-.58362.43506A11.9437,11.9437,0,0,0,4.076,17.37573a.50741.50741,0,0,0,.58459.4336l1.97412-.31568A.49751.49751,0,0,0,7.0462,16.94116Zm7.07746,7.86157a9.04484,9.04484,0,0,1-1.772-.575.49777.49777,0,0,0-.65234.22242L10.794,26.23267a.50749.50749,0,0,0,.23358.68921,12.02609,12.02609,0,0,0,2.58606.83862.50737.50737,0,0,0,.59369-.42066l.3139-1.97412A.49764.49764,0,0,0,14.12366,24.80273Zm6.177-.35253a.49777.49777,0,0,0-.65234-.22242,9.04484,9.04484,0,0,1-1.772.575.49764.49764,0,0,0-.39758.563l.3139,1.97412a.50737.50737,0,0,0,.59369.42066,12.02609,12.02609,0,0,0,2.58606-.83862.50749.50749,0,0,0,.23358-.68921ZM5.76245,10.8042l1.7832.90332a.49764.49764,0,0,0,.65772-.20581A8.96712,8.96712,0,0,1,9.29291,9.99438a.49781.49781,0,0,0-.01026-.689L7.86639,7.89429a.50749.50749,0,0,0-.72772.00952,11.95858,11.95858,0,0,0-1.59472,2.206A.5078.5078,0,0,0,5.76245,10.8042ZM17,4.05078V1.95435a.5.5,0,0,0-.78463-.41112L11.09381,5.08887a.5.5,0,0,0,0,.82226l5.12156,3.54564A.5.5,0,0,0,17,9.04565V7.05908a8.99269,8.99269,0,0,1,5.69226,14.9527.498.498,0,0,0,.00913.68961l1.41516,1.4151a.50275.50275,0,0,0,.72206-.00772A11.98713,11.98713,0,0,0,17,4.05078Z"
                />
            </svg>
        </div>
        <MultiSelect
            :list="channels"
            placeholder="Kanalfiltrering"
            :onSelect="selectChannels"
            v-if="channelFilter"
            type="channel"
        />
        <MultiSelect
            :list="[
                {
                    value: 'ARCHIVE',
                    name: 'Arkiv'
                },
                ...Array.from(streamingServices, (service) => {
                    return { value: service._id, name: service.name };
                })
            ]"
            placeholder="Service filtrering"
            :onSelect="selectServices"
            v-if="filters && streamingServices"
            type="service"
        />
        <div class="groupFilter" v-if="groupToggle">
            <button
                :class="
                    selectedGroup == 'personal' ? 'groupBtn active' : 'groupBtn'
                "
                @click="
                    (e) => {
                        setMarker(e, 0, 'personal');
                    }
                "
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                        d="M512 256c0 141.4-114.6 256-256 256S0 397.4 0 256S114.6 0 256 0S512 114.6 512 256zM256 352c-53 0-96-43-96-96s43-96 96-96s96 43 96 96s-43 96-96 96zm0 32c70.7 0 128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128s57.3 128 128 128zm0-96c17.7 0 32-14.3 32-32s-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32z"
                    />
                </svg>
                <span>Mine optagelser</span>
            </button>
            <button
                :class="
                    selectedGroup == 'family' ? 'groupBtn active' : 'groupBtn'
                "
                @click="
                    (e) => {
                        setMarker(e, 1, 'family');
                    }
                "
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path
                        d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"
                    />
                </svg>
                <span>Husstandens optagelser</span>
            </button>
            <button
                :class="
                    selectedGroup == 'watchlist'
                        ? 'groupBtn active'
                        : 'groupBtn'
                "
                @click="
                    (e) => {
                        setMarker(e, 2, 'watchlist');
                    }
                "
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                        d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"
                    />
                </svg>
                <span>Min liste</span>
            </button>
        </div>
        <div class="inputGroup">
            <input
                @input="(e) => search(e.target.value)"
                type="text"
                name="searchQuery"
                v-model="keyword"
                id="searchQuery"
                :placeholder="placeholder"
            />
        </div>
    </form>
</template>

<script>
import emitter from 'tiny-emitter/instance';
import MultiSelect from '@/components/MultiSelect.vue';
export default {
    name: 'SearchBar',
    props: {
        placeholder: String,
        onSearch: Function,
        groupToggle: Boolean,
        filters: Boolean,
        channelFilter: Boolean,
        channels: Array
    },
    components: {
        MultiSelect
    },
    data() {
        return {
            placeholders: {
                standard: 'Søg i indhold',
                guide: 'Søg i tv-guide',
                streaming: 'Søg',
                personal: 'Søg i mine optagelser',
                family: 'Søg i husstandens optagelser',
                watchlist: 'Søg i min liste'
            },
            keyword: '',
            searchResults: [],
            selectedProgram: {},
            programModal: false,
            selectedGroup: 'personal',
            groupMarkerPosition: 0,
            selectedServices: '',
            streamingServices: this.$store.state.user.profile.streamingServices,
            selectedChannels: '',
            searchTimer: null
        };
    },
    mounted() {
        let self = this;
        emitter.on('setSearchService', function (service) {
            self.selectedService = service;
            self.search('');
        });
        emitter.on('profileChange', this.setStreamingServices);
    },
    beforeUnmount() {
        emitter.off('resetChannelFilter');
        emitter.off('profileChange', this.setStreamingServices);
        emitter.off('setSearchService');
    },
    methods: {
        setStreamingServices() {
            this.streamingServices =
                this.$store.state.user.profile.streamingServices;
        },
        setMarker(e, position, group) {
            if (e) {
                e.preventDefault();
            }
            this.groupMarkerPosition = position * 60;
            this.selectedGroup = group;
            this.search(this.keyword);
        },
        search(keyword) {
            let self = this;
            let searchSettings = {
                keyword: keyword,
                group: self.selectedGroup,
                service: self.selectedServices,
                channel: self.selectedChannels
            };
            if (!this.filters) {
                delete searchSettings.service;
            }
            if (!this.channelFilter || !this.selectedChannels) {
                delete searchSettings.channel;
            }
            if (!this.groupToggle) delete searchSettings.group;
            if (this.searchTimer) {
                clearTimeout(this.searchTimer);
                this.searchTimer = setTimeout(() => {
                    this.onSearch(searchSettings);
                }, 300);
            } else {
                this.searchTimer = setTimeout(() => {
                    this.onSearch(searchSettings);
                }, 300);
            }
        },
        resetSearch() {
            let searchSettings = {
                keyword: '',
                group: this.selectedGroup,
                service: '',
                reset: true
            };
            if (!this.filters) {
                delete searchSettings.service;
            }
            if (!this.groupToggle) delete searchSettings.group;
            this.keyword = '';
            this.selectedServices = '';
            this.onSearch(searchSettings);
            emitter.emit('resetSearchFilters');
            emitter.emit('resetSearch');
        },
        selectChannels(channels) {
            this.selectedChannels = channels;
        },
        selectServices(services) {
            this.selectedServices = services;
        }
    },
    watch: {
        selectedServices: function (newValue) {
            if (newValue) {
                this.search(this.keyword);
            }
        },
        selectedChannels: function (newValue) {
            if (newValue) {
                this.search(this.keyword);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import './variables.scss';
form.searchForm {
    display: flex;
    align-items: center;
    height: 40px;
    width: max-content;
    margin: 15px 0;
    & > * {
        margin-left: 10px;
    }
    .inputGroup {
        margin-left: 10px;
        position: relative;
        input {
            width: 300px;
            border-radius: 10px;
            overflow: hidden;
            transition: 0.2s linear;
            height: 40px;
            padding: 0 20px;
            background-color: rgb(var(--secondaryBackground));
            color: rgb(var(--primaryTextColor));
            &::placeholder {
                color: rgba(var(--secondaryTextColor), 0.35);
            }
            &:focus {
                outline: 0;
            }
        }
    }
    select {
        height: 100%;
        background-color: rgb(var(--secondaryBackground));
        color: rgb(var(--secondaryTextColor));
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 0 20px;
        border-radius: 10px;
        color: rgb(var(--themeColor));
        cursor: pointer;
        text-align: center;
        &:focus {
            outline: 0;
        }
    }
    .resetSearch {
        border-radius: 5px;
        height: 30px;
        display: flex;
        align-items: center;
        gap: 5px;
        svg {
            height: 25px;
            width: 25px;
            path {
                fill: rgb(var(--themeColor));
            }
        }
    }
    .groupFilter {
        display: flex;
        background-color: rgb(var(--secondaryBackground));
        border-radius: 10px;
        position: relative;
        height: 40px;
        width: max-content;
        button.groupBtn {
            background-color: transparent;
            position: inherit;
            height: 100%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            padding: 0 10px;
            border-radius: inherit;
            color: rgb(var(--secondaryTextColor));
            font-size: 13px;
            svg {
                height: 20px;
                width: 20px;
                margin-right: 5px;
                path {
                    fill: rgb(var(--secondaryTextColor));
                }
            }
            &.active {
                background-color: rgba(var(--tertiaryBackground), 0.9);
                border-radius: 10px;
                color: rgb(var(--primaryTextColor));
                svg {
                    path {
                        fill: rgb(var(--primaryTextColor));
                    }
                }
            }
            &:hover {
                background-color: rgba(var(--secondaryTextColor), 0.5);
                color: rgb(var(--secondaryBackground));
                svg {
                    path {
                        fill: rgb(var(--secondaryBackground));
                    }
                }
            }
        }
    }
}
</style>
