<template>
    <div class="userSettings">
        <h1 class="settingsTitle">Indstillinger</h1>
        <div class="settingsMain">
            <div class="userSettingsMain">
                <SettingCategory heading="Brugernavn">
                    <div class="settingItem disabled">
                        <span class="settingTitle">{{
                            $store.state.lastValidate.username
                        }}</span>
                    </div>
                </SettingCategory>
                <SettingCategory :style="{ marginTop: '-20px' }">
                    <form
                        @submit="(e) => e.preventDefault()"
                        class="passwordForm settingItem"
                        v-if="editingPass"
                    >
                        <input
                            type="password"
                            placeholder="Ny kode"
                            class="settingTitle"
                            v-model="newPass"
                        />
                        <div class="settingAction flexContainer">
                            <svg
                                id="glyphicons-basic"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 32 32"
                                @click="handleNewPass"
                            >
                                <path
                                    id="check"
                                    d="M27.37592,9.70459l-14.151,15.97693a.99985.99985,0,0,1-1.47558.02356L4.59711,18.1322a.99992.99992,0,0,1-.05384-1.31128L5.495,15.63123a.99994.99994,0,0,1,1.22808-.26966L12,18,24.79724,7.09863a.99991.99991,0,0,1,1.35553.0542l1.1817,1.18164A1,1,0,0,1,27.37592,9.70459Z"
                                />
                            </svg>
                            <svg
                                id="glyphicons-basic"
                                class="exitEditing"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 32 32"
                                @click="
                                    () => {
                                        newPass = '';
                                        editingPass = false;
                                    }
                                "
                            >
                                <path
                                    id="times"
                                    d="M20.24249,16l5.65692,5.65674-4.24218,4.24267L16,20.24237l-5.65723,5.657L6.10059,21.65674,11.75751,16,6.10059,10.34326l4.24218-4.24267L16,11.75763l5.65723-5.657,4.24218,4.24267Z"
                                />
                            </svg>
                        </div>
                    </form>
                    <button
                        class="settingItem editPass"
                        v-if="!editingPass"
                        @click="
                            () => {
                                editingPass = true;
                            }
                        "
                    >
                        <span class="settingTitle">Rediger adgangskode</span>
                    </button>
                </SettingCategory>
                <SettingCategory heading="Enheder">
                    <router-link to="/settings/devices" class="settingItem">
                        <h3 class="settingTitle">Administrer enheder</h3>
                        <svg
                            id="glyphicons-basic"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 32 32"
                        >
                            <path
                                id="chevron-right"
                                d="M23.32849,16.70709,13.12115,26.91425a.50007.50007,0,0,1-.70715,0l-2.82806-2.8285a.5.5,0,0,1,0-.70709L16.96436,16,9.58594,8.62134a.5.5,0,0,1,0-.70709L12.414,5.08575a.50007.50007,0,0,1,.70715,0L23.32849,15.29291A.99988.99988,0,0,1,23.32849,16.70709Z"
                            />
                        </svg>
                    </router-link>
                </SettingCategory>
                <SettingCategory
                    heading="Husstandens optagelser"
                    :description="`Denne handling sletter alle hustandens optagelser. Disse indbefatter også optagelser knyttet til bestemte profiler. Hustanden har brugt ${recordingInfo.used} ud af tilladte ${recordingInfo.total} timer`"
                >
                    <button
                        class="settingItem centerText deleteUserRecordings"
                        @click="deleteAllRecordings"
                    >
                        <span class="settingTitle">Slet alle optagelser</span>
                    </button>
                </SettingCategory>
            </div>
            <router-view v-slot="{ Component, route }">
                <transition :name="route.meta.transition">
                    <component :is="Component" />
                </transition>
            </router-view>
        </div>
    </div>
</template>

<script>
import SettingCategory from '@/components/SettingCategory.vue';
import axios from 'axios';
import message from '@/components/statusMessage';
export default {
    name: 'UserSettings',
    components: {
        SettingCategory
    },
    data() {
        return {
            devices: [],
            selectedStreamingOptions: [],
            resellerInfo: this.$store.state.resellerInfo,
            recordingInfo: {
                used: '',
                total: 0
            },
            editingPass: false,
            newPass: ''
        };
    },
    beforeMount() {},
    mounted() {
        this.fetchRecordings();
    },
    methods: {
        deleteAllRecordings() {
            let self = this;
            let localStorage = window.localStorage;
            let dataUrl = `${
                this.$store.state.apiUrl
            }recording/ALL/delete?token=${localStorage.getItem('token')}`;
            axios
                .get(dataUrl)
                .then((res) => {
                    if (res.data.success == true) {
                        self.fetchRecordings();
                        message.Success('Slettede alle brugerens optagelser');
                    }
                })
                .catch((err) => {
                    console.error(err);
                    message.Error('Kunne ikke slette brugerens optagelser');
                });
        },
        fetchRecordings() {
            let self = this;
            let token = window.localStorage.getItem('token');
            axios
                .get(`${this.$store.state.apiUrl}recording?token=${token}`)
                .then((res) => {
                    let data = res.data;
                    let minutes = data.used % 60;
                    let hours = Math.floor(data.used / 60);
                    self.recordingInfo = {
                        used: `${hours} timer ${
                            minutes ? `og ${minutes} minutter` : ''
                        }`,
                        total: data.storage
                    };
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        handleNewPass() {
            let self = this;
            let localStorage = window.localStorage;
            let newPass = this.newPass;
            let dataUrl = `${
                this.$store.state.apiUrl
            }account?token=${localStorage.getItem('token')}&username=${
                this.$store.state.lastValidate.username
            }&password=${newPass}`;
            console.log(newPass);
            axios
                .get(dataUrl)
                .then((res) => {
                    if (res.data && res.data.success) {
                        message.Success('Din adgangskode blev opdateret');
                        self.editingPass = false;
                        self.newPass = '';
                    }
                })
                .catch(({ response }) => {
                    let error = response.data.error;
                    if (error) {
                        if (error.code == 'password') {
                            console.error(error);
                            message.Error(
                                'Adgangskoden er tom eller under 6 karakterer'
                            );
                        }
                    }
                });
        }
    },
    watch: {
        '$store.state.resellerInfo': function (state) {
            this.resellerInfo = state;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../components/variables.scss';
.userSettings {
    grid-area: settings;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    .settingsMain {
        display: flex;
        height: 100%;
        position: relative;
        .userSettingsMain {
            flex-grow: 1;
        }
    }

    .settingItem {
        &.editPass {
            .settingTitle {
                color: rgb(var(--themeColor));
            }
        }
        &.deleteUserRecordings {
            background-color: rgb(var(--themeColor));
        }
        &.centerText {
            .settingTitle {
                width: 100%;
                text-align: center;
                color: inherit;
            }
        }
    }
    .passwordForm {
        input {
            background-color: transparent;
            outline: 0;
            margin-right: 15px;
        }
        .exitEditing {
            margin-left: 10px;
        }
    }
}
</style>
