<template>
    <div
        class="overlay"
        @click.self="
            () => {
                onClose();
            }
        "
    >
        <div class="supportModal" v-if="resellerInfo">
            <h1 class="heading">{{ resellerInfo.resellerName }}</h1>
            <div class="content">
                <p v-if="resellerInfo.resellerNumber">
                    <svg
                        id="glyphicons-basic"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                    >
                        <path
                            id="call"
                            d="M27.01355,23.48859l-1.753,1.75305a5.001,5.001,0,0,1-5.19928,1.18243c-1.97193-.69372-4.87335-2.36438-8.43848-5.9295S6.387,14.028,5.6933,12.05615A5.00078,5.00078,0,0,1,6.87573,6.85687L8.62878,5.10376a1,1,0,0,1,1.41431.00012l2.828,2.8288A1,1,0,0,1,12.871,9.3468L11.0647,11.153a1.0038,1.0038,0,0,0-.0821,1.32171,40.74278,40.74278,0,0,0,4.07624,4.58374,40.74143,40.74143,0,0,0,4.58374,4.07623,1.00379,1.00379,0,0,0,1.32171-.08209l1.80622-1.80627a1,1,0,0,1,1.41412-.00012l2.8288,2.828A1.00007,1.00007,0,0,1,27.01355,23.48859Z"
                        /></svg
                    ><a href="tel:+4570223235">{{
                        resellerInfo.resellerNumber
                    }}</a>
                </p>
                <p v-if="resellerInfo.resellerEmail">
                    <svg
                        id="glyphicons-basic"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                    >
                        <path
                            id="envelope"
                            d="M14.69305,17.707,3.986,7h24L17.27893,17.707a1.15255,1.15255,0,0,1-.70709.293H15.40015A1.15257,1.15257,0,0,1,14.69305,17.707ZM10.17169,16,4,9.82812V22.17188Zm11.65656,0L28,22.17188V9.82812Zm-3.428,3.41418A2.3055,2.3055,0,0,1,16.986,20H14.986a2.30474,2.30474,0,0,1-1.41424-.58594l-1.99988-2L3.986,25h24l-7.58581-7.58594Z"
                        /></svg
                    ><a href="mailto:info@powernet.dk">{{
                        resellerInfo.resellerEmail
                    }}</a>
                </p>
                <p v-if="resellerInfo.resellerWeb">
                    <svg
                        id="glyphicons-basic"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                    >
                        <path
                            id="globe"
                            d="M12.142,6.77588A9.93171,9.93171,0,0,0,10.01489,10H8.0191A10.04454,10.04454,0,0,1,12.142,6.77588ZM6.84082,12h2.5578a17.33119,17.33119,0,0,0-.372,3H6.05072A9.91039,9.91039,0,0,1,6.84082,12Zm0,8a9.91039,9.91039,0,0,1-.7901-3H9.02661a17.33119,17.33119,0,0,0,.372,3ZM8.0191,22h1.99579A9.93171,9.93171,0,0,0,12.142,25.22412,10.04454,10.04454,0,0,1,8.0191,22ZM15,25.06036A9.08814,9.08814,0,0,1,12.1947,22H15ZM15,20H11.43512a14.63849,14.63849,0,0,1-.41174-3H15Zm0-5H11.02338a14.63849,14.63849,0,0,1,.41174-3H15Zm0-5H12.1947A9.08814,9.08814,0,0,1,15,6.93964Zm8.9809,0h-1.996a9.934,9.934,0,0,0-2.12768-3.22443A10.0449,10.0449,0,0,1,23.9809,10ZM17,6.93982A9.09076,9.09076,0,0,1,19.805,10H17ZM17,12h3.56476a14.63792,14.63792,0,0,1,.41186,3H17Zm0,5h3.97662a14.63792,14.63792,0,0,1-.41186,3H17Zm0,8.06018V22h2.805A9.09076,9.09076,0,0,1,17,25.06018Zm2.85724.16425A9.934,9.934,0,0,0,21.98492,22h1.996A10.0449,10.0449,0,0,1,19.85724,25.22443ZM25.15924,20H22.60132a17.325,17.325,0,0,0,.37207-3h2.97589A9.91,9.91,0,0,1,25.15924,20Zm-2.18585-5a17.325,17.325,0,0,0-.37207-3h2.55792a9.91,9.91,0,0,1,.79,3ZM28,16A12,12,0,1,0,16,28,12,12,0,0,0,28,16Z"
                        /></svg
                    ><a href="https://powernet.dk" target="_blank">{{
                        resellerInfo.resellerWeb
                    }}</a>
                </p>
            </div>
        </div>
        <LoadingIcon class="supportLoadingIcon" v-else :size="40" />
    </div>
</template>

<script>
import axios from 'axios';
import LoadingIcon from '@/components/LoadingIcon.vue';
import message from '@/components/statusMessage';
export default {
    name: 'SupportModal',
    components: {
        LoadingIcon
    },
    props: {
        onClose: Function
    },
    data() {
        return {
            resellerInfo: null
        };
    },
    mounted() {
        this.fetchResellerInfo();
    },
    methods: {
        fetchResellerInfo() {
            let token = window.localStorage.getItem('token');
            if (!token) return;

            let dataUrl = `${this.$store.state.apiUrl}validate?token=${token}`;
            axios
                .get(dataUrl)
                .then(({ data }) => {
                    this.$store.state.user.hasnPvr = data.nPvr;
                    this.resellerInfo = {
                        resellerName: data.resellerName,
                        resellerNumber: data.resellerNumber,
                        resellerEmail: data.resellerEmail,
                        resellerWeb: data.resellerWeb
                    };
                })
                .catch((err) => {
                    console.error(err);
                    message.Error('Kunne ikke hente forhandler information');
                    if (typeof this.onClose == 'function') {
                        this.onClose();
                    }
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background-color: rgba(var(--secondaryBackground), 0.9);
    display: flex;
    .supportModal {
        padding: 30px 50px;
        background-color: rgb(var(--primaryBackground));
        color: rgb(var(--primaryTextColor));
        border-radius: 20px;
        margin: auto;
        display: flex;
        flex-direction: column;
        .heading {
            font-size: 40px;
            margin: 0 auto;
            position: relative;
            margin-bottom: 20px;
            &:after {
                left: 0;
                content: '';
                height: 4px;
                border-radius: 2px;
                width: 100%;
                position: absolute;
                top: 100%;
                background-color: rgb(var(--secondaryTextColor));
            }
        }
        .content {
            margin: 0 auto;
            svg {
                height: 60px;
                width: 60px;
                margin-right: 10px;
                path {
                    fill: rgb(var(--primaryTextColor));
                }
            }
            p {
                display: flex;
                font-size: 25px;
                align-items: center;
            }
        }
    }
    .supportLoadingIcon {
        margin: auto;
    }
}
</style>
