<template>
    <div class="login">
        <form @submit="handleLogin" id="loginForm" class="loginForm">
            <img class="branding" src="/img/icon.png" alt="Branding" />
            <div
                :class="{
                    inputGroup: true,
                    error: usernameError
                }"
            >
                <label for="username" class="inputLabel">Brugernavn</label>
                <input
                    :disabled="loading ? true : false"
                    name="username"
                    id="username"
                    type="text"
                    placeholder="Indsæt brugernavn her..."
                    @input="
                        () => {
                            usernameError = false;
                        }
                    "
                />
            </div>
            <div
                :class="{
                    inputGroup: true,
                    error: passwordError
                }"
            >
                <label for="password" class="inputLabel">Adgangskode</label>
                <input
                    :disabled="loading ? true : false"
                    name="passeword"
                    id="password"
                    type="password"
                    placeholder="Indsæt adgangskode her..."
                    @input="
                        () => {
                            passwordError = false;
                        }
                    "
                />
            </div>

            <button
                :disabled="loading ? true : false"
                class="submitBtn"
                type="submit"
            >
                {{ loading ? 'Logger ind..' : 'Log ind' }}
            </button>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import message from '@/components/statusMessage';
import emitter from 'tiny-emitter/instance';
export default {
    name: 'LoginMain',
    data() {
        return {
            loading: false,
            usernameError: false,
            passwordError: false
        };
    },
    methods: {
        handleLogin(event) {
            var self = this;
            let form = event.target;
            let storage = window.localStorage;
            let userCredentials = {
                username: form.username.value,
                password: form.password.value
            };
            event.preventDefault();
            if (userCredentials.username == '') {
                message.Error('Brugernavn må ikke være tom');
                this.usernameError = true;
                return;
            }
            if (userCredentials.password == '') {
                message.Error('Adgangskoden må ikke være tom');
                this.passwordError = true;
                return;
            }
            if (this.loading) return;
            this.loading = true;
            let body = {
                username: userCredentials.username,
                password: userCredentials.password,
                deviceId: localStorage.getItem('deviceId'),
                type: 'WEB',
                model: encodeURIComponent(navigator.userAgent)
            };
            axios
                .post(self.$store.state.apiUrl + 'login', body)
                .then((res) => {
                    let data = res.data;
                    storage.setItem('token', data.token);
                    self.$store.state.user = {
                        loggedIn: true,
                        token: data.token,
                        profile: ''
                    };
                    self.$router.push({ path: '/' });
                    emitter.emit('profileChange');
                })
                .catch((err) => {
                    let res = err.response;
                    if (res.status == 403) {
                        message.Error(
                            'Brugernavn eller adgangskode passer ikke'
                        );
                    } else if (res.status == 409) {
                        message.Error(
                            'Du har ikke flere enheder, slet en enhed for at logge ind'
                        );
                    } else {
                        message.Error('Der skete en fejl');
                    }
                    this.loading = false;
                    document.querySelector('form.loginForm').password.value =
                        '';
                    // Needs proper error handling at some point
                });
            // this.saveCredentials();
        }
    },
    beforeMount() {
        let storage = window.localStorage;
        if (storage.getItem('token')) {
            this.$router.push({ path: '/' });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../components/variables.scss';
.login {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
    background-color: rgb(var(--primaryBackground));
    display: flex;
    justify-content: center;
    align-items: center;
    form.loginForm {
        background-color: rgb(var(--secondaryBackground));
        height: 400px;
        width: 400px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: left;
        .branding {
            margin-bottom: 20px;
            height: 100px;
            width: auto;
        }
        .inputGroup {
            margin: 5px 0;
            width: 80%;
            &.error {
                label {
                    color: red;
                }
                input {
                    border: 2px solid red;
                }
            }
            label {
                display: block;
                margin-bottom: 5px;
                color: rgb(var(--secondaryTextColor));
            }

            input {
                padding: 10px 20px;
                display: block;
                width: 100%;
                border-radius: 5px;
                background-color: rgb(var(--primaryBackground-brighter));
                color: rgb(var(--primaryTextColor));
                &:disabled {
                    cursor: not-allowed;
                    background-color: rgb(var(--primaryBackground));
                    box-shadow: none;
                }
                &:focus {
                    outline: 0;
                    box-shadow: 0 0 3px 4px rgba(16, 16, 16, 0.638);
                }
            }
        }
        button.submitBtn {
            padding: 10px 20px;
            background-color: rgb(var(--primaryBackground-bright));
            color: rgb(var(--primaryTextColor));
            font-size: 20px;
            margin-top: 20px;
            cursor: pointer;
            border-radius: 5px;

            &:hover {
                box-shadow: 0 0 3px 4px rgba(16, 16, 16, 0.638);
                &:disabled {
                    box-shadow: none;
                }
            }
            &:disabled {
                background-color: rgb(var(--primaryBackground));
                color: rgb(var(--secondaryTextColor));
                cursor: not-allowed;
            }
        }
    }
}
</style>
