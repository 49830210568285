<template>
    <div class="contentCategory">
        <header
            class="categoryHeader"
            @click="
                () =>
                    clickAction
                        ? clickAction({ value: category, name: title })
                        : null
            "
        >
            <h2>
                {{ title
                }}<svg
                    v-if="typeof clickAction == 'function'"
                    width="21px"
                    height="21px"
                    viewBox="0 0 21 21"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                    <title>chevron_right</title>
                    <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                    >
                        <path
                            d="M7.92068182,16.2612305 C8.09841619,16.2612305 8.24880682,16.199707 8.37185369,16.0766602 L13.7859162,10.7788086 C13.915799,10.6489258 13.9841584,10.4985352 13.9841584,10.3276367 C13.9841584,10.1567383 13.915799,9.99951172 13.7859162,9.87646484 L8.37185369,4.57177734 C8.24880682,4.45556641 8.09841619,4.39404297 7.92068182,4.39404297 C7.57204901,4.39404297 7.29861151,4.66064453 7.29861151,5.01611328 C7.29861151,5.18701172 7.36697088,5.34423828 7.47634588,5.46044922 L12.4529084,10.3276367 L7.47634588,15.1948242 C7.36697088,15.3110352 7.29861151,15.4614258 7.29861151,15.6391602 C7.29861151,15.9946289 7.57204901,16.2612305 7.92068182,16.2612305 Z"
                            id="􀆊"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                        ></path>
                    </g>
                </svg>
            </h2>
        </header>
        <div class="carouselWrapper">
            <div
                class="skip skipBackwards"
                @click="
                    (e) => {
                        scroll(false, e);
                    }
                "
            >
                <svg
                    id="glyphicons-basic"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                >
                    <path
                        id="chevron-left"
                        d="M22.41406,23.37866a.5.5,0,0,1,0,.70709L19.586,26.91425a.50007.50007,0,0,1-.70715,0L8.67151,16.70709a.99988.99988,0,0,1,0-1.41418L18.87885,5.08575a.50007.50007,0,0,1,.70715,0l2.82806,2.8285a.5.5,0,0,1,0,.70709L15.03564,16Z"
                    />
                </svg>
            </div>
            <div class="categoryContainer">
                <slot />
            </div>
            <div
                class="skip skipForwards"
                @click="
                    (e) => {
                        scroll(true, e);
                    }
                "
            >
                <svg
                    id="glyphicons-basic"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                >
                    <path
                        id="chevron-right"
                        d="M23.32849,16.70709,13.12115,26.91425a.50007.50007,0,0,1-.70715,0l-2.82806-2.8285a.5.5,0,0,1,0-.70709L16.96436,16,9.58594,8.62134a.5.5,0,0,1,0-.70709L12.414,5.08575a.50007.50007,0,0,1,.70715,0L23.32849,15.29291A.99988.99988,0,0,1,23.32849,16.70709Z"
                    />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContentCategory',
    props: {
        title: String,
        clickAction: Function,
        category: String
    },
    mounted() {},
    methods: {
        scroll(direction, e) {
            let container =
                e.currentTarget.parentElement.querySelector(
                    '.categoryContainer'
                );
            // let container = document.querySelector('.categoryContainer');
            if (direction) {
                container.scrollLeft =
                    container.scrollLeft + window.innerWidth / 2;
            } else {
                container.scrollLeft =
                    container.scrollLeft - window.innerWidth / 2;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import './variables.scss';
.contentCategory {
    margin-bottom: 20px;
    width: 100%;
    header.categoryHeader {
        text-align: left;
        width: max-content;
        margin: 0 20px;
        h2 {
            cursor: pointer;
        }
        h2:hover > svg {
            transform: translateX(10px);
            path {
                fill: rgb(var(--primaryTextColor));
            }
        }
        svg {
            display: inline-block;
            margin-left: 5px;
            transition: 0.1s linear;
            path {
                fill: rgb(var(--secondaryTextColor));
            }
        }
    }
    .carouselWrapper {
        width: 100%;
        overflow-x: hidden;
        position: relative;
        padding: 0 20px;
        .categoryContainer {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: 250px;
            gap: 20px;
            padding: 10px 20px;
            width: 100%;
            overflow-x: scroll;
            scroll-behavior: smooth;
        }
        .skip {
            position: absolute;
            height: 100%;
            width: 60px;
            z-index: 1;
            background-color: rgba(var(--primaryBackground), 0.6);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin: 0 20px;
            &Backwards {
                left: 0;
                top: 0;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }
            &Forwards {
                right: 0;
                top: 0;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            svg {
                height: 50px;
                width: 50px;
                transition: height, width;
                transition-duration: 0.2s;
                path {
                    fill: rgb(var(--secondaryTextColor));
                }
            }
            &:hover {
                svg {
                    height: 60px;
                    width: 60px;
                    path {
                        fill: rgb(var(--primaryTextColor));
                    }
                }
            }
        }
    }
}
</style>
