<template>
    <div
        class="progressionBar"
        v-if="
            typeof customWidth != 'undefined' ||
            (start < new Date() && end > new Date()) ||
            progress
        "
    >
        <div
            class="progress"
            :style="{
                width:
                    (typeof customWidth != 'undefined'
                        ? customWidth
                        : ((progress
                              ? progress / 60
                              : getMinDiff(start, new Date())) /
                              duration) *
                          100) + '%'
            }"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'ProgressBar',
    methods: {
        getMinDiff(startDate, endDate) {
            const msInMinute = 60 * 1000;

            return Math.round(Math.abs(endDate - startDate) / msInMinute);
        }
    },
    props: {
        start: Object,
        end: Object,
        duration: Number,
        customWidth: String,
        progress: Number
    }
};
</script>

<style lang="scss" scoped>
@import './variables.scss';
.progressionBar {
    width: 100%;
    background-color: rgb(var(--secondaryBackground-brighter));
    height: 5px;
    margin-top: auto;
    border-radius: 2.5px;
    overflow: hidden;

    .progress {
        width: 100%;
        height: 100%;
        background-color: rgb(var(--themeColor));
    }
}
</style>
