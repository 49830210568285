<template>
    <div
        class="settingItem device"
        :style="{ transitionDelay: (index + 1) * 0.02 + 's' }"
    >
        <button class="deleteDevice" @click="deleteDevice">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                    d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"
                />
            </svg>
        </button>
        <div class="content">
            <div class="deviceInfo">
                <span>Navn</span>
                <div class="deviceName">
                    <h3 v-if="!editingName" class="heading">
                        {{ nameInput }}
                    </h3>
                    <input
                        v-if="editingName"
                        type="text"
                        v-model="nameInput"
                        id="nameInput"
                        name="nameInput"
                    />
                    <button
                        class="edit"
                        @click="
                            () => {
                                if (editingName) {
                                    editName();
                                }
                                editingName = !editingName;
                            }
                        "
                    >
                        <svg
                            v-if="!editingName"
                            id="glyphicons-basic"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 32 32"
                        >
                            <path
                                id="pencil"
                                d="M27.414,8.82812l-2.08588,2.08594L21.08588,6.67188l2.08587-2.08594a.99985.99985,0,0,1,1.41425,0l2.828,2.82812A.99986.99986,0,0,1,27.414,8.82812ZM8.08594,19.67188l4.24218,4.24218L23.91406,12.32812,19.67181,8.08594ZM4.27667,27.09863a.50005.50005,0,0,0,.62476.62476l5.92773-2.48023L6.75677,21.1709Z"
                            />
                        </svg>
                        <svg
                            v-if="editingName"
                            id="glyphicons-basic"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 32 32"
                        >
                            <path
                                id="check"
                                d="M27.37592,9.70459l-14.151,15.97693a.99985.99985,0,0,1-1.47558.02356L4.59711,18.1322a.99992.99992,0,0,1-.05384-1.31128L5.495,15.63123a.99994.99994,0,0,1,1.22808-.26966L12,18,24.79724,7.09863a.99991.99991,0,0,1,1.35553.0542l1.1817,1.18164A1,1,0,0,1,27.37592,9.70459Z"
                            />
                        </svg>
                    </button>
                </div>
                <span>Model</span>
                <p>{{ device.model }}</p>
                <span>Sidst set</span>
                <p>{{ lastComm }}</p>
                <span>Først set</span>
                <p>{{ firstComm }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'deviceCard',
    props: {
        device: Object,
        index: Number,
        onDelete: Function
    },
    data() {
        return {
            last: new Date(this.lastSeen),
            editingName: false,
            nameInput: this.device.name,
            lastComm: '',
            firstComm: ''
        };
    },
    mounted() {
        this.lastComm = this.createDateLine(new Date(this.device.lastComm));
        this.firstComm = this.createDateLine(new Date(this.device.created));
    },
    methods: {
        editName() {
            let self = this;
            let token = window.localStorage.getItem('token');
            let dataUrl = `${this.$store.state.apiUrl}device/${this.device._id}?token=${token}&name=${this.nameInput}`;
            axios.get(dataUrl).catch((err) => {
                console.error('There has been an error', err);
                self.$store.dispatch('validateLogin');
            });
        },
        deleteDevice() {
            let self = this;
            let token = window.localStorage.getItem('token');
            let dataUrl = `${this.$store.state.apiUrl}device/${this.device._id}/delete?token=${token}`;
            axios
                .get(dataUrl)
                .then(() => {
                    self.onDelete(this.device._id);
                })
                .catch((err) => {
                    console.error('There has been an error', err);
                    self.$store.dispatch('validateLogin');
                });
        },
        correctClockNum(num) {
            return num < 10 ? '0' + num : num;
        },
        createDateLine(date) {
            return `${this.correctClockNum(
                date.getDate()
            )}-${this.correctClockNum(
                date.getMonth() + 1
            )}-${date.getFullYear()} ${this.correctClockNum(
                date.getHours()
            )}:${this.correctClockNum(
                date.getMinutes()
            )}:${this.correctClockNum(date.getSeconds())}`;
        }
    }
};
</script>

<style lang="scss" scoped>
@import './variables.scss';
.device {
    cursor: default;
    justify-content: flex-start;
    max-height: 200px;
    overflow: hidden;
    .deleteDevice {
        cursor: pointer;
        background-color: red;
        height: 25px;
        width: 25px;
        flex-shrink: 0;
        border-radius: 50%;
        svg {
            height: 20px;
            width: 20px;
            path {
                fill: white !important;
            }
        }
    }
    &:hover {
        .deleteDevice {
            svg {
                path {
                    fill: white !important;
                }
            }
        }
        svg {
            path {
                fill: orange !important;
            }
        }
    }
    .content {
        flex-direction: column;
        margin-left: 20px;
        flex-grow: 1;
        .deviceInfo {
            display: grid;
            grid-template-columns: max-content 1fr;
            align-items: center;
            .deviceName {
                display: flex;
                .heading {
                    text-align: left;
                    font-size: 19px;
                }
                button {
                    margin-left: 10px;
                    background-color: transparent;
                    svg {
                        path {
                            fill: orange;
                        }
                    }
                }
                input {
                    background-color: rgb(var(--secondaryBackground));
                    color: rgb(var(--primaryTextColor));
                    padding: 0 10px;
                }
            }
            p {
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                line-clamp: 1;
                -webkit-box-orient: vertical;
                word-break: break-all;
                color: rgb(var(--primaryTextColor));
                font-size: 16px;
            }
            span {
                margin-right: 10px;
                font-size: 13px;
                text-align: right;
                color: rgb(var(--secondaryTextColor));
            }
        }
    }
}
</style>
