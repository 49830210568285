<template>
    <div class="appWrapper">
        <PrimaryNavigation v-if="$store.state.user.loggedIn" />
        <router-view />
    </div>
</template>
<script>
import PrimaryNavigation from './components/PrimaryNavigation.vue';
import axios from 'axios';
import emitter from 'tiny-emitter/instance';
export default {
    data() {
        return {
            playerActive: false,
            startConfig: {
                programInfo: false,
                programGuide: false,
                channelGuide: false
            },
            playerData: {},
            live: false,
            mode: 'channel'
        };
    },
    components: {
        PrimaryNavigation
    },
    beforeMount() {
        let localStorage = window.localStorage;
        let userValues = {
            token: localStorage.getItem('token') || null
        };
        if (!userValues.token) {
            localStorage.removeItem('token');
            this.$store.commit('setUser', {
                token: '',
                loggedIn: false
            });
        } else if (userValues.token) {
            this.$store.commit('setUser', {
                token: userValues.token,
                loggedIn: true
            });
        }

        // Make deviceId on first time;
        if (localStorage.getItem('deviceId') === null) {
            localStorage.setItem('deviceId', uuid());
        }
        function uuid() {
            let uuid = '',
                i,
                random;
            for (i = 0; i < 32; i++) {
                random = (Math.random() * 16) | 0;
                if (i === 8 || i === 12 || i === 16 || i === 20) {
                    uuid += '-';
                }
                uuid += (
                    i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random
                ).toString(16);
            }
            return uuid;
        }
    },
    mounted() {
        if (!this.$store.state.user.loggedIn) {
            this.$router.push('/login');
        }
        this.fetchSimpleChannelList();
    },
    watch: {
        '$store.state.user.profile._id': function () {
            emitter.emit('profileChange');
            this.fetchSimpleChannelList();
        },
        '$store.state.user.token': function (newToken) {
            if (newToken) {
                this.validateUser();
            }
        }
    },
    methods: {
        increase_brightness(hex, percent) {
            // strip the leading # if it's there
            hex = hex.replace(/^\s*#|\s*$/g, '');

            // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
            if (hex.length == 3) {
                hex = hex.replace(/(.)/g, '$1$1');
            }

            var r = parseInt(hex.substr(0, 2), 16),
                g = parseInt(hex.substr(2, 2), 16),
                b = parseInt(hex.substr(4, 2), 16);

            return (
                '#' +
                (0 | ((1 << 8) + r + ((256 - r) * percent) / 100))
                    .toString(16)
                    .substr(1) +
                (0 | ((1 << 8) + g + ((256 - g) * percent) / 100))
                    .toString(16)
                    .substr(1) +
                (0 | ((1 << 8) + b + ((256 - b) * percent) / 100))
                    .toString(16)
                    .substr(1)
            );
        },
        hexToRgb(hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result
                ? `${parseInt(result[1], 16)}, ${parseInt(
                      result[2],
                      16
                  )}, ${parseInt(result[3], 16)}`
                : null;
        },
        brigthenhex(color, percent) {
            if (!color || !percent) return;
            let brighthex = this.increase_brightness(color, percent);
            return brighthex;
        },
        closePlayer() {
            this.playerActive = false;
            this.startConfig = {};
            this.playerData = {};
            this.live = false;
            this.mode = 'channel';
        },
        validateUser() {
            let self = this;
            let localStorage = window.localStorage;
            let token = localStorage.getItem('token');
            let dataUrl = `${this.$store.state.apiUrl}validate?token=${token}&version=WEB`;
            axios
                .get(dataUrl)
                .then((res) => {
                    let data = res.data;
                    self.$store.commit('setReseller', {
                        name: data.resellerName,
                        number: data.resellerNumber,
                        email: data.resellerEmail,
                        web: data.resellerWeb
                    });
                    self.$store.commit('setLastValidate', data);
                    let variableStyles = document.createElement('style');
                    variableStyles.innerHTML = `:root{
                    ${this.getColorLine('primaryTextColor', data.textColor)};
                    ${this.getColorLine(
                        'secondaryTextColor',
                        data.secondaryTextColor
                    )}
                    ${this.getColorLine('themeColor', data.accentColor)};
                    ${this.getColorLine(
                        'primaryBackground',
                        data.backgroundColor
                    )}
                    ${this.getColorLine(
                        'primaryBackground-bright',
                        self.brigthenhex(data.backgroundColor, 10)
                    )}
                    ${this.getColorLine(
                        'primaryBackground-brighter',
                        self.brigthenhex(data.backgroundColor, 20)
                    )}
                    ${this.getColorLine(
                        'secondaryBackground',
                        data.secondaryBackgroundColor
                    )}
                    ${this.getColorLine(
                        'secondaryBackground-bright',
                        self.brigthenhex(data.secondaryBackgroundColor, 10)
                    )}
                    ${this.getColorLine(
                        'secondaryBackground-brighter',
                        self.brigthenhex(data.secondaryBackgroundColor, 20)
                    )}
                    ${this.getColorLine(
                        'topbarBackground',
                        data.topTabBarColor
                    )}
                    ${this.getColorLine(
                        'tertiaryBackground',
                        data.tertiaryBackgroundColor
                    )}`;
                    document.head.insertAdjacentElement(
                        'beforeend',
                        variableStyles
                    );
                    this.setFavicon(token);
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status == 403) {
                        localStorage.removeItem('token');
                        self.$store.commit('setUser', {
                            token: '',
                            loggedIn: false
                        });
                        self.$router.push('/login');
                    }
                });
        },
        getColorLine(label, color) {
            if (!color) {
                return '';
            } else {
                return `--${label}: ${this.hexToRgb(color)};`;
            }
        },
        setFavicon(token) {
            if (!token) {
                console.log('Kunne ikke sætte favicon');
            }
            let head = document.getElementsByTagName('head')[0];
            let favicon = document.createElement('link');
            favicon.rel = 'shortcut icon';
            favicon.href = `${this.$store.state.apiUrl}branding?token=${token}`;
            head.insertAdjacentElement('afterbegin', favicon);
        },
        fetchSimpleChannelList() {
            let self = this;
            let localStorage = window.localStorage;
            let token = localStorage.getItem('token');
            let profile = localStorage.getItem('lastProfile');
            let dataUrl = `${this.$store.state.apiUrl}channel?token=${token}&profile=${profile}&epgMode=NONE`;
            axios
                .get(dataUrl)
                .then(({ data }) => {
                    self.$store.state.channelList = data;
                })
                .catch((err) => console.error(err));
        }
    }
};
</script>
<style lang="scss">
@import '@/components/variables.scss';
@import '@/components/bitmovinStyle.scss';
@import '@/components/statusMessage.scss';
.scrollbars {
    &::-webkit-scrollbar {
        display: unset;
        width: 1em;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(var(--primaryBackground), 0.5);
        border-radius: 0.5em;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    min-height: 100vh;
    background-color: rgb(var(--primaryBackground));
}
* {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    &::-webkit-scrollbar {
        display: none;
    }
}
.v-enter-active,
.v-leave-active {
    transition: all 0.2s ease;
}

.v-enter-active .player {
    transition: transform 0.2s ease;
}
.v-leave-active .player {
    transition: transform 0.2s ease;
}

.v-enter-from.player,
.v-leave-to.player {
    transform: translateY(100px);
}
.v-enter-from,
.v-leave-to {
    opacity: 0;
}

// Helper classes
.flex {
    display: flex;
    &-center {
        display: flex;
        justify-content: center;
    }
    &-bottom {
        display: flex;
        align-items: flex-end;
    }
}
h1,
h2,
h3 {
    color: rgb(var(--primaryTextColor));
}
h1 {
    font: $primaryHeading-large;
}
h2 {
    font: $primaryHeading-normal;
}
h3 {
    font: $primaryHeading-small;
}
p {
    color: rgb(var(--secondaryTextColor));
}
h1.pageHeading {
    padding: 20px 0;
}
.flexContainer {
    display: flex;
    align-items: center;
}
button {
    color: white;
    svg {
        path {
            fill: white;
        }
    }
}
a {
    color: white;
}
.settingItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(var(--secondaryBackground-bright));
    padding: 10px 20px;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    position: relative;

    &.disabled {
        filter: brightness(0.9);
        cursor: default;
        &:hover {
            .settingTitle {
                color: rgb(var(--secondaryTextColor));
            }
        }
    }
    .settingTitle,
    svg path {
        transition: color 0.1s linear, fill 0.1s linear;
        fill: rgb(var(--secondaryTextColor));
    }
    &.router-link-active {
        background-color: rgb(var(--tertiaryBackground));
        color: rgb(var(--primaryTextColor));
        &.accountSettings {
            background-color: rgb(var(--secondaryBackground-bright));

            .settingTitle {
                color: rgb(var(--secondaryTextColor));
                svg {
                    path {
                        fill: rgb(var(--secondaryTextColor));
                    }
                }
            }
        }
        .settingTitle {
            color: rgb(var(--primaryTextColor)) !important;
            svg {
                path {
                    fill: rgb(var(--primaryTextColor));
                }
            }
        }
        svg {
            path {
                fill: rgb(var(--primaryTextColor)) !important;
            }
        }
    }
    &:hover {
        .settingTitle {
            color: rgb(var(--primaryTextColor));
            svg {
                path {
                    fill: rgb(var(--primaryTextColor));
                }
            }
        }
        svg {
            path {
                fill: rgb(var(--primaryTextColor)) !important;
            }
        }
    }
    .settingTitle {
        font-weight: 400;
        font-size: 15px;
        line-height: 25px;
        color: rgb(var(--secondaryTextColor));
        display: flex;
        align-items: center;
        width: 100%;
        svg {
            height: 25px;
            width: 25px;
            path {
                fill: rgb(var(--secondaryTextColor));
            }
        }
    }
    &.addProfile {
        .settingTitle {
            width: max-content;
            margin: 0 auto;
        }
    }
    &:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        overflow: hidden;
        &:after {
            display: none;
        }
    }
    svg {
        height: 20px;
        width: 20px;
    }
    &:after {
        content: '';
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 20px;
        right: 20px;
        background: rgba(128, 128, 128, 0.15);
    }
}
.appWrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.channels-enter-active,
.channels-leave-active,
.channels-move {
    transition: all 0.4s cubic-bezier(0.55, 0, 0.1, 1);
}
.channels-enter-from,
.channels-leave-to {
    opacity: 0 !important;
    padding-top: 0;
    padding-bottom: 0;
}
.channels-leave-active {
    position: absolute;
    left: 50%;
    bottom: 0;
}
</style>
