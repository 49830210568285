import { createStore } from 'vuex';
import router from '../router/index.js';
import axios from 'axios';

export default createStore({
  state: {
    user: {
      loggedIn: false,
      token: '',
      profile: ''
    },
    // apiUrl: 'http://10.10.10.189:3000/iptv/app/',
    apiUrl: 'https://api.powernet.tv/iptv/app/',
    resellerInfo: {
      name: '',
      number: 0,
      email: '',
      web: ''
    },
    lastValidate: {

    },
    channelList: []
  },
  getters: {
    getUserToken(state) {
      return state.user.token;
    }
  },
  mutations: {
    setUser (state, newUserObj) {
      state.user = {
        loggedIn: newUserObj.loggedIn,
        token: newUserObj.token,
        profile: ''
      }
    },
    setReseller (state, resellerInfo) {
      state.resellerInfo = resellerInfo;
    },
    setLastValidate(state, validateData) {
      state.lastValidate = validateData;
    }
  },
  actions: {
    validateLogin(state) {
      let dataUrl = `${state.state.apiUrl}validate?token=${state.state.user.token}&version=WEB`
      axios.get(dataUrl).then(res => {
        let data = res.data;
        state.commit('setReseller', {
          name: data.resellerName,
          number: data.resellerNumber,
          email: data.resellerEmail,
          web: data.resellerWeb
        })
        state.commit('setLastValidate', res.data);
      }).catch(err => {
        console.error('validate error', err.response);
        if (err.response && err.response.data && err.response.data.code == 'access_denied') {
          let localStorage = window.localStorage;
  
          localStorage.removeItem('token');
          state.commit('setUser', {
            token: '',
            loggedIn: false
          })
          router.push({path: '/login'});
        }
      })
    }
  },
  modules: {
  }
})
