<template>
    <div
        :class="{
            channelCard: true,
            selected: currentChannel == channel._id ? true : false
        }"
        @click="onSelect(channel._id)"
    >
        <img class="channelLogo" :src="channel.logo" alt="" />
        <div class="content">
            <p class="duration">{{ programStart }} - {{ programEnd }}</p>
            <p class="playingNow">{{ currentProgram.title }}</p>
            <p class="nextProgram">{{ nextStart }} {{ nextProgram?.title }}</p>
        </div>
        <img
            class="programImg"
            :src="`https://image.powernet.tv/${currentProgram.imageIds?.[0]}?width=200&height=80`"
            alt=""
        />
    </div>
</template>

<script>
export default {
    name: 'ChannelCard',
    data() {
        return {
            currentProgram: this.channel.epg[0],
            nextProgram: this.channel.epg?.[1],
            programStart: '',
            programEnd: '',
            nextStart: ''
        };
    },
    props: {
        currentChannel: String,
        channel: Object,
        onSelect: Function
    },
    mounted() {
        this.programStart = this.constructTime(this.currentProgram.start);
        if (this.currentProgram.end) {
            this.programEnd = this.constructTime(this.currentProgram.end);
        }
        if (this.nextProgram) {
            this.nextStart = this.constructTime(this.nextProgram.start);
        }
    },
    methods: {
        constructTime(time) {
            let t = new Date(time);
            let h = t.getHours() < 10 ? '0' + t.getHours() : t.getHours();
            let m = t.getMinutes() < 10 ? '0' + t.getMinutes() : t.getMinutes();
            return `${h}:${m}`;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/components/variables.scss';
.channelCard {
    display: flex;
    height: 120px;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 15px;
    background-color: rgba(var(--primaryBackground), 0.5);
    transition: box-shadow 0.1s linear, transform 0.1s linear;
    margin: 0 20px;
    cursor: pointer;
    &.selected {
        border: 3px solid rgb(var(--themeColor));
    }
    &:first-of-type {
        margin-top: 10px;
    }
    &:last-of-type {
        margin-bottom: 10px;
    }
    &:hover {
        box-shadow: $defaultShadow;
        transform: scale(1.05);
    }
    img.channelLogo {
        height: 100%;
        width: 8rem;
        flex-grow: 0;
        object-fit: contain;
    }
    .content {
        margin: 10px 5px;
        display: flex;
        flex-direction: column;
        .playingNow {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
            color: rgb(var(--primaryTextColor));
            font-weight: bold;
            font-size: 23px;
            line-height: 26px;
        }
        .nextProgram {
            margin-top: auto;
        }
    }
    img.programImg {
        flex-shrink: 0;
        width: 30%;
        height: 100%;
        object-fit: cover;
        background-position: 50% 50%;
        margin-left: auto;
    }
}
</style>
