<template>
    <div :class="`select ${!open ? 'closed' : ''}`">
        <p
            class="selectLabel"
            v-if="!open"
            @click="
                () => {
                    open = true;
                    focusInput();
                }
            "
        >
            {{ values.length > 0 ? values[0].name : placeholder }}
            <strong>{{
                values.length > 1 ? ` +${values.length - 1}` : ''
            }}</strong>
        </p>
        <input
            type="text"
            class="searchInput selectLabel"
            v-else
            :placeholder="placeholder"
            @input="handleSearchInput"
            v-model="searchFilter"
        />
        <div class="selectContent scrollbars" v-if="open">
            <div
                class="selectOption"
                @click="
                    () => {
                        selectOption(option);
                    }
                "
                v-for="(option, index) in searchFilter
                    ? list.filter((o) => {
                          return o.name
                              .toLowerCase()
                              .includes(searchFilter.toLowerCase());
                      })
                    : list"
                :key="index"
            >
                <svg
                    v-if="
                        values.findIndex((o) => {
                            return o.value == option.value;
                        }) > -1
                            ? true
                            : false
                    "
                    id="glyphicons-basic"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                >
                    <path
                        id="check"
                        d="M27.37592,9.70459l-14.151,15.97693a.99985.99985,0,0,1-1.47558.02356L4.59711,18.1322a.99992.99992,0,0,1-.05384-1.31128L5.495,15.63123a.99994.99994,0,0,1,1.22808-.26966L12,18,24.79724,7.09863a.99991.99991,0,0,1,1.35553.0542l1.1817,1.18164A1,1,0,0,1,27.37592,9.70459Z"
                    />
                </svg>
                <div class="optionLabel">{{ option.name }}</div>
            </div>
            <p
                v-if="
                    searchFilter
                        ? list.filter((o) => {
                              return o.name
                                  .toLowerCase()
                                  .includes(searchFilter.toLowerCase());
                          }).length < 1
                        : list.length < 1
                "
            >
                Ingen resultater
            </p>
        </div>
        <div
            class="selectOverlay"
            v-if="open"
            @click.stop="
                () => {
                    closeSelect();
                }
            "
        ></div>
    </div>
</template>

<script>
import emitter from 'tiny-emitter/instance';
export default {
    name: 'CustomSelect',
    props: {
        list: Array,
        onSelect: Function,
        placeholder: String,
        type: String
    },
    data() {
        return {
            valueString: '',
            values: [],
            open: false,
            topOffset: 0,
            scrollOffset: 0,
            valueLabel: '',
            searchFilter: ''
        };
    },
    mounted() {
        emitter.on('resetSearchFilters', () => {
            this.valueString = '';
            this.values = [];
            this.valueLabel = '';
        });
        emitter.on(`set${this.type}`, (content) => {
            this.values.push(content);
        });
    },
    beforeUnmount() {
        emitter.off('resetSearchFilters');
        emitter.off(`set${this.type}`);
    },
    methods: {
        handleSearchInput({ target }) {
            if (target) {
                this.searchFilter = target.value;
            }
        },
        focusInput() {
            this.$nextTick(() => {
                let input = document.querySelector('input.searchInput');
                if (input) {
                    input.focus();
                }
            });
        },
        selectOption(option) {
            let optionIndex = this.values.findIndex((o) => {
                return o.value == option.value;
            });
            if (optionIndex > -1) {
                this.values.splice(optionIndex, 1);
                this.constructStringValues();
            } else {
                this.values.push(option);
                this.constructStringValues();
            }
        },
        constructStringValues() {
            let string = '';
            let label = '';
            this.values.forEach((item, index) => {
                if (index + 1 < this.values.length) {
                    string += `${item.value},`;
                    label += `${item.name},`;
                } else {
                    string += `${item.value}`;
                    label += `${item.name}`;
                }
            });
            this.valueString = string;
            this.valueLabel = label;
        },
        openSelect() {
            this.open = true;
        },
        closeSelect() {
            this.open = false;
            this.searchFilter = '';
        },
        getScrollOffset() {
            let scrollContainer = document.querySelector('ul.optionContainer');
            if (!scrollContainer) return;
            return scrollContainer.scrollTop;
        }
    },
    watch: {
        valueString: function (value) {
            this.onSelect(value);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/components/variables.scss';
.select {
    z-index: 9999;
    position: relative;
    height: 100%;
    &.closed {
        z-index: 999;
    }
    input.selectLabel {
        color: rgb(var(--primaryTextColor));
        cursor: unset;
    }
    .selectLabel {
        position: relative;
        z-index: 10;
        height: 100%;
        font-size: 15px;
        border-radius: 10px;
        width: 200px;
        font-weight: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        margin: auto 0;
        cursor: pointer;
        background-color: rgb(var(--secondaryBackground));
        transition: transform;
        transition-duration: 0.1s;
        transition-timing-function: ease;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: keep-all;
        display: flex;
        align-items: center;
        &:focus {
            outline: 0;
        }
        &:active {
            transform: scale(0.9);
        }
    }
    .selectContent {
        visibility: visible;
        transition: height opacity background-color padding;
        transition-duration: 0.3s;
        // transition-delay: 0.1s;
        transition-timing-function: ease;
        position: absolute;
        z-index: 2;
        top: calc(100% + 5px);
        background-color: rgb(var(--secondaryBackground));
        list-style: none;
        width: 200px;
        max-height: 200px;
        left: 0;
        border-radius: 10px;
        padding: 10px;
        overflow-y: scroll;
        border: 1px solid rgba(var(--primaryBackground), 0.5);
        .selectOption {
            display: grid;
            align-items: center;
            grid-template-columns: 30px 1fr;
            padding: 5px 10px;
            cursor: pointer;
            grid-template-areas: 'icon label';
            color: rgb(var(--secondaryTextColor));
            text-align: left;
            &:hover {
                color: rgb(var(--primaryTextColor));
            }
            svg {
                grid-area: icon;
                height: 20px;
                width: 20px;
                margin-right: 10px;
                path {
                    fill: rgb(var(--themeColor));
                }
            }
            .optionLabel {
                grid-area: label;
            }
            &.selected {
                position: relative;
                z-index: 9;
                color: rgb(var(--themeColor));
                font-weight: bold;
            }
            &:hover {
                color: rgb(var(--primaryTextColor));
            }
        }
    }
    .selectOverlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: transparent;
        z-index: 1;
    }
}
</style>
