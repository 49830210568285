<template>
    <div :class="{ toggle: true, active: activeState }" @click="setState">
        <div class="toggleMarker"></div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line
    name: 'Toggle',
    props: {
        onSet: Function,
        activeState: Boolean
    },
    methods: {
        setState() {
            let newState = !this.activeState;
            this.onSet(newState, true);
        }
    }
};
</script>

<style lang="scss" scoped>
.toggle {
    width: 2.5rem;
    aspect-ratio: 2 / 1;
    background-color: lightgray;
    position: relative;
    border-radius: 1.25rem;
    transition: background-color;
    transition-duration: 0.2s;
    display: flex;
    &.active {
        background-color: rgb(var(--themeColor));
        .toggleMarker {
            left: calc(100% - 1rem - 2px);
        }
    }

    .toggleMarker {
        position: absolute;
        top: 2px;
        left: 2px;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        height: calc(100% - 4px);
        background-color: white;
        transition: left, transform;
        transition-duration: 0.2s;
        transition-timing-function: ease;
    }
}
</style>
