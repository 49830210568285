<template>
    <div class="dateLine">
        <div class="prevPage farSkip" v-if="skipButtons" @click="skipBackward">
            <svg id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <path id="chevron-left" d="M22.41406,23.37866a.5.5,0,0,1,0,.70709L19.586,26.91425a.50007.50007,0,0,1-.70715,0L8.67151,16.70709a.99988.99988,0,0,1,0-1.41418L18.87885,5.08575a.50007.50007,0,0,1,.70715,0l2.82806,2.8285a.5.5,0,0,1,0,.70709L15.03564,16Z"/>
            </svg>
        </div>
            <TransitionGroup name="date" tag="div" class="dates" :direction="direction">
                <div
                    :class="
                        date.date.setHours(0, 0, 0, 0) ==
                        selectedDate.setHours(0, 0, 0, 0)
                            ? 'date selected'
                            : 'date'
                    "
                    v-bind:key="date.date.getDate()"
                    v-for="date in dateArray"
                    @click="() => onSet(date.date)"
                >
                    <p class="day" v-if="!date.today">{{ date.day }}</p>
                    <p class="date" v-if="!date.today">
                        {{ date.date.getDate() }}/{{ date.date.getMonth() + 1 }}
                    </p>
                    <p class="today" v-if="date.today">I dag</p>
                </div>
            </TransitionGroup>
        <div class="nextPage farSkip" v-if="skipButtons" @click="skipForward">
            <svg id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <path id="chevron-right" d="M23.32849,16.70709,13.12115,26.91425a.50007.50007,0,0,1-.70715,0l-2.82806-2.8285a.5.5,0,0,1,0-.70709L16.96436,16,9.58594,8.62134a.5.5,0,0,1,0-.70709L12.414,5.08575a.50007.50007,0,0,1,.70715,0L23.32849,15.29291A.99988.99988,0,0,1,23.32849,16.70709Z"/>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DateLine',
    props: {
        onSet: Function,
        dateArray: Array,
        selectedDate: Object,
        skipButtons: Boolean,
        onForwards: Function,
        onBackwards: Function,
        direction: String
    },
    methods: {
        skipForward() {
            if (typeof this.onForwards == 'function') {
                this.onForwards();
            }
        },
        skipBackward() {
            if (typeof this.onBackwards == 'function') {
                this.onBackwards();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import './variables.scss';
.dateLine {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    text-align: center;
    position: relative;
    &:after {
        z-index: 0;
        content: '';
        position: absolute;
        aspect-ratio: 1 / 1;
        top: -5px;
        bottom: -5px;
        background-color: rgb(var(--themeColor));
        border-radius: 50%;
    }
    .dates {
        display: flex;
        gap: 5px;
        align-items: center;
        gap: 10px;
        height: 50px;
        position: relative;
        width: 370px;
        overflow-x: hidden;
        background-color: rgb(var(--secondaryBackground));
        padding: 0 10px;
        border-radius: 5px;

        
        div.date {
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;
            height: 45px;
            width: 45px;
            position: relative;
            z-index: 1;
            
            p {
                font-size: 16px;
            }
            &.selected {
                p {
                    color: rgb(var(--primaryTextColor));
                }
                
            }
        }
        &[direction=forwards] {
            .date-enter-from {
                opacity: 0 !important;
                padding-top: 0;
                padding-bottom: 0;
                transform: translateX(370px);
            }
            .date-leave-to {
                opacity: 0 !important;
                padding-top: 0;
                padding-bottom: 0;
                transform: translateX(-370px);
            }
            .date-leave-active {
                position: absolute !important;
            }
        }
        &[direction=backwards] {
            .date-enter-from {
                opacity: 0 !important;
                padding-top: 0;
                padding-bottom: 0;
                transform: translateX(-370px);
            }
            .date-leave-to {
                opacity: 0 !important;
                padding-top: 0;
                padding-bottom: 0;
                transform: translateX(calc(370px * 1.7));
            }
            .date-leave-active {
                position: absolute !important;
            }
            
        }
        .date-enter-active,
        .date-leave-active,
        .date-move {
            transition: all .4s cubic-bezier(0.55, 0, 0.1, 1);
        }
        .date-leave-active, .date-enter-active {
            padding: 0 !important;
            margin: 0 !important;
        }
        // .date-leave-active {
        //     position: absolute !important;
        // }
    }
    .farSkip {
        background-color: rgb(var(--secondaryBackground));
        height: 100%;
        padding: 0 3px;
        display: flex;
        border-radius: 5px;
        cursor: pointer;
        border: 2px solid transparent;
        transition: border transform;
        transition-duration: .1s;
        svg {
            height: 20px;
            width: 20px;
            margin: auto 0;
            path {
                transition: fill;
                transition-duration: .1s;
                fill: rgb(var(--secondaryTextColor));
            }
        }

        &:hover {
            border: 2px solid rgb(var(--themeColor));
            transform: scale(1.2);
            svg {
            
                path {
                    fill: rgb(var(--primaryTextColor));
                }
            }
        }
        &:active {
            transform: scale(1);
        }
    }

}
</style>
