<template>
    <div class="logOnByCode">
        <div class="logOnByCodeForm" @click.stop>
            <h1>Indtast koden fra din enhed</h1>
            <input type="text" class="logOnByCodeInput" v-model="logoncode" />
            <button @click="submitByCode">Godkend</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import message from '@/components/statusMessage';
export default {
    name: 'LogOnByCode',
    data() {
        return {
            logoncode: ''
        };
    },
    methods: {
        submitByCode() {
            let token = window.localStorage.getItem('token');
            if (!this.logoncode || this.logoncode.length != 4) {
                message.Error('Koden skal være 4 cifre lang');
                return;
            }
            let dataUrl = `${
                this.$store.state.apiUrl
            }loginbycode?token=${token}&code=${this.logoncode.toUpperCase()}`;
            axios
                .get(dataUrl)
                .then(() => {
                    this.logoncode = '';
                    this.$router.push('/');
                })
                .catch((err) => {
                    console.error(err);
                    message.Error('');
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.logOnByCode {
    display: flex;
    &Form {
        margin: auto;
        width: 50%;
        height: 50%;
        min-width: 350px;
        min-height: 300px;
        background-color: rgb(var(--primaryBackground));
        border-radius: 15px;
        padding: 20px 50px;

        input {
            display: block;
            background-color: rgb(var(--secondaryBackground));
            padding: 10px 20px;
            border-radius: 10px;
            min-width: 70px;
            max-width: 200px;
            margin: 10px auto;
            text-align: center;
            color: rgb(var(--primaryTextColor));
            font-size: 35px;
            font-weight: bold;
            text-transform: uppercase;
            &:focus {
                outline: 0;
            }
        }
        button {
            background-color: rgb(var(--themeColor));
            padding: 10px 50px;
            color: rgb(var(--primaryTextColor));
            cursor: pointer;
            transition: transform border-radius;
            transition-duration: 0.1s;
            transition-timing-function: ease;
            border-radius: 10px;

            &:hover {
                transform: scale(1.05, 1.1);
                border-radius: 4px;
            }
            &:active {
                transform: scale(1);
            }
        }
    }
}
</style>
