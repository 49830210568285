<template>
    <div class="guideWrapper">
        <header class="header">
            <DateLine
                :onSet="setDate"
                :dateArray="highlightedDates"
                :selectedDate="new Date(selectedDate)"
                :skipButtons="true"
                :onForwards="setNextWeek"
                :onBackwards="setPrevWeek"
                :direction="dateLineDirection"
            />
            <SearchMain
                :filters="false"
                :groupToggle="false"
                :onSearch="handleSearch"
                placeholder="Søg i tv-guiden"
                :channels="searchChannels"
                :channelFilter="true"
            />
        </header>
        <div class="guideGrid" v-if="!searchView">
            <button
                @click="
                    () => {
                        moveGuide('backward');
                    }
                "
                class="skip skipBackwards"
            >
                <svg
                    id="glyphicons-basic"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                >
                    <path
                        id="chevron-left"
                        d="M22.41406,23.37866a.5.5,0,0,1,0,.70709L19.586,26.91425a.50007.50007,0,0,1-.70715,0L8.67151,16.70709a.99988.99988,0,0,1,0-1.41418L18.87885,5.08575a.50007.50007,0,0,1,.70715,0l2.82806,2.8285a.5.5,0,0,1,0,.70709L15.03564,16Z"
                    />
                </svg>
            </button>
            <button
                @click="
                    () => {
                        moveGuide('forward');
                    }
                "
                class="skip skipForwards"
            >
                <svg
                    id="glyphicons-basic"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                >
                    <path
                        id="chevron-right"
                        d="M23.32849,16.70709,13.12115,26.91425a.50007.50007,0,0,1-.70715,0l-2.82806-2.8285a.5.5,0,0,1,0-.70709L16.96436,16,9.58594,8.62134a.5.5,0,0,1,0-.70709L12.414,5.08575a.50007.50007,0,0,1,.70715,0L23.32849,15.29291A.99988.99988,0,0,1,23.32849,16.70709Z"
                    />
                </svg>
            </button>
            <div class="nowBtnWrapper">
                <button class="nowBtn" @click="goToNow">Nu</button>
            </div>
            <TimeLine :modifier="1.5" />
            <div class="programs">
                <div
                    v-if="today"
                    class="nowLine"
                    :style="{
                        left: `calc(${
                            getNowLinePosition(new Date()) + 'rem'
                        } + 120px)`
                    }"
                ></div>
                <div
                    class="programRow"
                    v-bind:key="channel._id"
                    v-for="channel in channels"
                >
                    <div
                        v-bind:key="channel._id"
                        class="channel"
                        @click="
                            () => {
                                openPlayer(
                                    channel._id,
                                    channel.epg?.find((o) => {
                                        return (
                                            new Date(o.start) < new Date() &&
                                            new Date(o.end) > new Date()
                                        );
                                    }),
                                    'channel'
                                );
                            }
                        "
                    >
                        <img :src="channel.logo" alt="" />
                    </div>
                    <GuideProgram
                        v-for="(program, index) in channel.epg"
                        v-bind:key="program._id"
                        :channel="channel._id"
                        :program="program"
                        @click="
                            () => {
                                if (
                                    new Date(program.availableUntil) >
                                    new Date()
                                ) {
                                    openPlayer(channel._id, program, 'program');
                                }
                            }
                        "
                        :startDate="new Date(selectedDate)"
                        :first="index == 0 ? true : false"
                    />
                </div>
                <div
                    class="loadingRows"
                    v-if="totalChannels > currentOffset + 10"
                >
                    Loader...
                </div>
            </div>
        </div>
        <Transition v-else-if="searchView">
            <div class="searchView">
                <div
                    class="searchGrid"
                    v-if="searchResults.length > 0 || searching"
                >
                    <ProgramCard
                        v-for="program in searchResults"
                        :key="program._id"
                        :program="{
                            ...program,
                            thumbnail: `https://image.powernet.tv/${program.imageIds?.[0]}?width=200&height=80`
                        }"
                        @click="
                            () => {
                                openPlayer(null, program, 'search');
                            }
                        "
                    />
                </div>
                <h1 v-else>Ingen resultater</h1>
            </div>
        </Transition>
        <Transition>
            <Player
                v-if="player"
                :streamId="playerData.streamId"
                :defaultProgram="playerData.program"
                :onClose="closePlayer"
                :startConfig="{
                    programInfo:
                        playerData.trigger == 'program' ||
                        playerData.trigger == 'search'
                            ? true
                            : false,
                    programGuide:
                        playerData.trigger == 'channel' ? true : false,
                    channelGuide: false
                }"
                :channelId="playerData.channelId"
                :mode="playerData.mode"
                :autoplay="
                    playerData.trigger == 'channel' || playerData.program.live
                        ? true
                        : false
                "
                :closeOnMenuClose="
                    new Date() < new Date(playerData.program.start)
                        ? true
                        : false
                "
            />
        </Transition>
    </div>
</template>
<script>
import GuideProgram from '@/components/GuideProgram.vue';
import DateLine from '../components/DateLine.vue';
import TimeLine from '../components/TimeLine.vue';
import Player from '../components/Player.vue';
import axios from 'axios';
import emitter from 'tiny-emitter/instance';
import SearchMain from '@/components/Search.vue';
import message from '@/components/statusMessage';
import ProgramCard from '@/components/programCard.vue';
export default {
    name: 'TvGuide',
    components: {
        DateLine,
        TimeLine,
        Player,
        SearchMain,
        ProgramCard,
        GuideProgram
    },
    data() {
        return {
            player: false,
            highlightedDates: [],
            selectedDate: new Date(),
            playerData: {
                channelId: '',
                program: {}
            },
            today: true,
            lastUsedPeriod: {},
            channels: [],
            next: '',
            searchView: false,
            searchResults: [],
            searching: false,
            dateLineDirection: '',
            searchChannels: [],
            currentOffset: 0,
            totalChannels: 0
        };
    },
    beforeMount() {},
    mounted() {
        let self = this;
        this.setHighlightedDates(new Date());
        this.setDate(new Date());
        let wrapper = document.querySelector('div.guideGrid');
        let options = {
            root: wrapper,
            rootMargin: '150px 100px',
            treshold: [0.2, 0.4, 0.6, 0.8, 1.0]
        };
        if (!wrapper) return;
        this.setScrollPosition(this.today ? true : false);
        this.observer = new IntersectionObserver(self.handleObserve, options);
        this.lastObserver = new IntersectionObserver((e) => {
            let entry = e[0];
            if (entry.isIntersecting) {
                if (this.next) {
                    this.fetchGuide(this.next);
                }
                this.lastObserver.unobserve(entry.target);
            }
        }, options);

        emitter.on('profileChange', function () {
            self.channels = [];
            if (self.$route.name == 'guide') {
                self.setDate(new Date());
            }
        });
        if (this.$store.state.channelList.length > 0) {
            this.constructSearchChannels(this.$store.state.channelList);
        } else {
            console.log('Ingen kanaler');
        }
        // this.observer.observe(observerElem);
    },
    methods: {
        moveGuide(direction) {
            let guideContainer = document.querySelector('div.guideGrid');
            let innerWidth = window.innerWidth;
            if (direction == 'forward') {
                guideContainer.scrollLeft =
                    guideContainer.scrollLeft + innerWidth / 2;
            } else if (direction == 'backward') {
                guideContainer.scrollLeft =
                    guideContainer.scrollLeft - innerWidth / 2;
            }
        },
        openPlayer(channelId, program, trigger) {
            this.playerData.trigger = trigger;
            if (trigger == 'search') {
                this.playerData.mode = 'streaming';
            }
            if (trigger == 'program') {
                let endTime = {};
                if (program.end) {
                    endTime = new Date(program.end);
                } else {
                    let start = new Date(program.start);
                    let end = new Date(start).setMinutes(
                        start.getMinutes() + program.duration
                    );
                    endTime = end;
                }
                if (
                    new Date(program.start) < new Date() &&
                    new Date() < new Date(endTime)
                ) {
                    // It is live
                    this.playerData.mode = 'channel';
                    this.playerData.streamId = channelId;
                    this.playerData.channelId = channelId;
                } else {
                    this.playerData.mode = 'archive';
                    this.playerData.streamId = program._id;
                    this.playerData.channelId = program._id;
                }
            } else if (trigger == 'channel') {
                this.playerData.mode = 'channel';
                this.playerData.streamId = channelId;
                this.playerData.channelId = channelId;
            }
            this.playerData.program = program;
            if (
                new Date(program.start) < new Date() &&
                new Date(program.end) > new Date()
            ) {
                this.playerData.program.live = true;
            } else {
                this.playerData.program.live = false;
            }
            this.player = true;
        },
        closePlayer() {
            this.player = false;
            this.playerData = {};
        },
        getNowLinePosition(currentTime) {
            let startOfDay = new Date();
            startOfDay.setHours(0);
            startOfDay.setMinutes(0);
            startOfDay.setSeconds(0);

            let differenceInMinutes = this.getMinDiff(
                new Date(startOfDay),
                currentTime
            );
            let difference = differenceInMinutes / 1.5;
            return difference;
        },
        setHighlightedDates(focusDate) {
            let dates = [];
            let setDate = focusDate.getDate();
            for (let i = 0; i < 7; i++) {
                let addition = i - 3;
                let tmpDate = new Date(
                    new Date(focusDate).setDate(setDate + addition)
                );
                let thisDate = new Date();
                dates.push({
                    date: tmpDate,
                    day: tmpDate
                        .toLocaleString('da-DK', { weekday: 'short' })
                        .toUpperCase(),
                    today:
                        thisDate.setHours(0, 0, 0, 0) ==
                        tmpDate.setHours(0, 0, 0, 0)
                            ? true
                            : false
                });
            }
            this.highlightedDates = dates;
        },
        setNextWeek() {
            let currentDate = new Date(this.selectedDate);
            let newDate = new Date(currentDate).setDate(
                currentDate.getDate() + 7
            );
            this.setDate(new Date(newDate));
        },
        setPrevWeek() {
            let currentDate = new Date(this.selectedDate);
            let newDate = new Date(currentDate).setDate(
                currentDate.getDate() - 7
            );
            this.setDate(new Date(newDate));
        },
        setDate(dateToSet) {
            let self = this;
            if (new Date(dateToSet) < new Date(this.selectedDate)) {
                this.dateLineDirection = 'backwards';
            } else {
                this.dateLineDirection = 'forwards';
            }
            if (
                new Date(dateToSet).setHours(0, 0, 0, 0) ==
                new Date().setHours(0, 0, 0, 0)
            ) {
                this.today = true;
            } else {
                this.today = false;
            }
            let dateObject = new Date(dateToSet);
            let nextDate = new Date(
                new Date(dateObject).setDate(dateObject.getDate() + 1)
            );
            let fromDate = `${dateObject.getFullYear()}-${self.toTwoDigit(
                parseInt(dateObject.getMonth()) + 1
            )}-${self.toTwoDigit(dateObject.getDate())}`;

            let toDate = `${nextDate.getFullYear()}-${self.toTwoDigit(
                parseInt(nextDate.getMonth()) + 1
            )}-${self.toTwoDigit(nextDate.getDate())}`;
            if (
                this.$store.state.user.profile &&
                this.$store.state.user.profile._id
            ) {
                fromDate = new Date(new Date(fromDate).setHours(0, 0, 0, 0));
                toDate = new Date(new Date(toDate).setHours(0, 0, 0, 0));
                this.fetchGuide(null, {
                    from: new Date(fromDate).toISOString(),
                    to: new Date(toDate).toISOString()
                });
            }
            this.setScrollPosition(this.today ? true : false);
            dateObject.setHours(0, 0, 0, 0);
            this.setHighlightedDates(dateToSet);
            this.selectedDate = dateObject;
        },
        goToNow() {
            if (
                this.selectedDate.setHours(0, 0, 0, 0) ==
                new Date().setHours(0, 0, 0, 0)
            ) {
                this.setScrollPosition(true);
            } else {
                this.setDate(new Date());
            }
        },
        fetchGuide(customUrl, period) {
            if (!window.localStorage.getItem('lastProfile')) return;
            let localStorage = window.localStorage;
            let token = localStorage.getItem('token');
            let profile = localStorage.getItem('lastProfile');
            var self = this;
            let url =
                customUrl ||
                self.$store.state.apiUrl +
                    'channel?token=' +
                    token +
                    '&epgFull=true' +
                    '&epgMode=PERIOD' +
                    '&paging=true' +
                    '&limit=10' +
                    (period
                        ? `&from=${period.from}&to=${period.to}`
                        : `&from=${this.lastUsedPeriod.from}&to=${this.lastUsedPeriod.to}`) +
                    `&profile=${profile}`;

            if (period) {
                this.lastUsedPeriod = period;
            }
            axios
                .get(url)
                .then((res) => {
                    self.next = res.data.next;
                    if (customUrl) {
                        self.channels = [
                            ...self.channels,
                            ...res.data.channels
                        ];
                    } else {
                        let data = res.data.channels;
                        self.channels = data;
                    }
                    self.setObservers();
                    this.currentOffset = res.data.offset;
                    this.totalChannels = res.data.total;
                })
                .catch((err) => {
                    console.error(err);
                    self.$store.dispatch('validateLogin');
                });
        },
        getMinDiff(startDate, endDate) {
            const msInMinute = 60 * 1000;

            return Math.round(Math.abs(endDate - startDate) / msInMinute);
        },
        setScrollPosition(today) {
            let programContainer = document.querySelector('div.guideGrid');
            if (today) {
                this.$nextTick(() => {
                    let nowPosition = this.getNowLinePosition(new Date());
                    if (programContainer) {
                        programContainer.scrollLeft = (nowPosition - 10) * 16;
                    }
                });
            } else {
                this.$nextTick(() => {
                    if (programContainer) {
                        programContainer.scrollLeft = 0;
                    }
                });
            }
        },
        constructChannelImg(imageId) {
            return (
                'https://image.powernet.tv/' + imageId + '?width=100&height=100'
            );
        },
        handleObserve(e) {
            e.forEach((entry) => {
                if (entry) {
                    if (entry.isIntersecting) {
                        if (entry.target) {
                            entry.target.classList.remove('hidden');
                        }
                    } else {
                        if (entry.target) {
                            entry.target.classList.add('hidden');
                        }
                    }
                    // self.observer.unobserve(self.observerElem);
                    // if (self.next) {
                    //     self.fetchGuide(
                    //         self.next.replace(
                    //             'api.powernet.tv',
                    //             'playmakerdev.telecomx.dk'
                    //         )
                    //     );
                    // }
                }
            });
        },
        setObservers() {
            let self = this;
            this.$nextTick(() => {
                let programs = document.querySelectorAll(
                    '.guideProgramWrapper'
                );
                if (programs && programs.length > 0) {
                    for (let i = 0; i < programs.length; i++) {
                        const program = programs[i];
                        if (program) {
                            self.observer.unobserve(program);
                            self.observer.observe(program);
                        }
                    }
                    // let observerElem = channels[channels.length - 2];
                    // self.observerElem = observerElem;
                    // self.observer.observe(observerElem);
                }
                let rows = document.querySelectorAll('.programRow');
                let lastRow = rows[rows.length - 2];
                if (lastRow) {
                    this.lastObserver.observe(lastRow);
                }
            });
        },
        toTwoDigit(num) {
            return num < 10 ? '0' + num : num;
        },
        handleSearch(searchParams) {
            if (
                (searchParams.reset && searchParams.reset == true) ||
                (searchParams.keyword == '' && this.searching)
            ) {
                this.searchView = false;
                this.searchResults = [];
                if (this.today) {
                    this.goToNow();
                }
            } else {
                if (!searchParams.keyword) return;
                if (searchParams.keyword.length < 3) return;
                this.searchView = true;
                this.searching = true;
                this.search(
                    this.constructSearchUrl({
                        keyword: searchParams.keyword,
                        channels: searchParams.channel
                    })
                );
            }
        },
        constructSearchUrl({ keyword, channels }) {
            let localStorage = window.localStorage;
            let token = localStorage.getItem('token');
            let profile = localStorage.getItem('lastProfile');
            if (!profile || !token) return;
            let dataUrl = `${this.$store.state.apiUrl}program?token=${token}&profile=${profile}&mode=TV&filter=${keyword}&channel=${channels}`;
            return dataUrl;
        },
        search(url) {
            axios
                .get(url)
                .then(({ data }) => {
                    if (!data) return;
                    this.searchResults = data.programs;
                    this.searching = false;
                })
                .catch((err) => {
                    this.searching = false;
                    console.error(err);
                    message.Error('Der skete en fejl under søgning');
                });
        },
        constructSearchChannels(channels) {
            if (channels.length < 1) return;
            this.searchChannels = [];
            for (let i = 0; i < channels.length; i++) {
                const channel = channels[i];
                this.searchChannels.push({
                    name: channel.name,
                    value: channel.baseChannel
                });
            }
        }
    },
    watch: {
        '$store.state.channelList': function (channels) {
            this.constructSearchChannels(channels);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../components/variables.scss';
.guideWrapper {
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 95vh;
    max-height: calc(100vh - 100px);
    header.header {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin: 0 50px;
        .searchForm {
            margin: auto 0;
        }
    }
    .searchView {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        flex-grow: 1;
        overflow-y: scroll;
        padding: 20px 0;
        .searchGrid {
            display: grid;
            flex-shrink: 0;
            grid-template-columns: repeat(
                auto-fit,
                minmax(min(calc(100% / 2), max(250px, calc(100% / 7))), 1fr)
            );
            gap: 40px 20px;
            width: 90%;
            max-width: 1500px;
        }
    }
}
.guideGrid {
    position: relative;
    display: grid;
    grid-template-columns: 120px 1fr;
    grid-template-rows: max-content 1fr;
    grid-template-areas: 'nowBtn timeline' 'programs programs';
    flex-grow: 1;
    overflow-y: scroll;
    margin-top: 20px;
    scroll-behavior: smooth;
    .skip {
        position: fixed;
        background-color: rgba(var(--themeColor), 0.2);
        font-size: 40px;
        z-index: 9;
        height: 400px;
        max-height: 50vh;
        width: 70px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        transition: 0.2s ease;
        margin-top: 10vh;
        flex-grow: 1;
        border: 2px solid rgba(var(--themeColor), 0.2);
        cursor: pointer;
        svg {
            height: 50px;
            width: 50px;
        }
        // padding: 60px 20px;
        color: rgb(var(--primaryTextColor));
        &Forwards {
            right: 30px;
        }
        &Backwards {
            left: 170px;
        }
        &:hover {
            border: 3px solid rgb(var(--themeColor));
            transform: scale(1.1);
        }
    }
    .nowBtnWrapper {
        grid-area: nowBtn;
        width: 120px;
        height: max-content;
        background-color: rgb(var(--primaryBackground));
        position: sticky;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        height: 50px;
        z-index: 19;
        .nowBtn {
            margin: auto;
            background-color: transparent;
            color: rgb(var(--themeColor));
            padding: 0 20px;
            height: 100%;
            border-radius: 5px;
            transition: background-color 0.1s linear;
            cursor: pointer;
            font-size: 20px;
            &:hover {
                background-color: rgb(var(--secondaryBackground-brighter));
            }
        }
    }
    .programs {
        text-align: left;
        height: max-content;
        margin-bottom: 10px;
        padding: 10px 15px;
        scroll-behavior: smooth;
        grid-area: programs;
        position: relative;
        .container {
            display: grid;
            grid-auto-flow: row;
            position: relative;
        }
        .nowLine {
            position: absolute;
            left: 10em;
            z-index: 2;
            height: 100%;
            pointer-events: none;
            background-color: red;
            width: 1px;
        }

        .programRow {
            display: flex;
            height: 120px;
            margin-bottom: 10px;
            .channel {
                border-radius: 10px;
                height: 120px;
                width: 120px;
                cursor: pointer;
                background-color: whitesmoke;
                z-index: 10;
                margin-bottom: 10px;
                overflow: hidden;
                position: sticky;
                left: 0;
                flex-shrink: 0;

                &:hover {
                    box-shadow: $defaultShadow;
                    transform: scale(1.05);
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        .loadingRows {
            position: sticky;
            left: 50%;
            transform: translateX(-50%);
            width: max-content;
        }
    }
}
</style>

<style lang="scss">
.guideRow-enter-active,
.guideRow-leave-active,
.guideRow-move,
.guidePrograms-move,
.guidePrograms-enter-active,
.guidePrograms-leave-active {
    transition: all 0.4s cubic-bezier(0.55, 0, 0.1, 1);
}
.guideRow-enter-from,
.guideRow-leave-to {
    height: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}
.guidePrograms-enter-from,
.guidePrograms-leave-to {
    height: 0 !important;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0 !important;
}

.container {
    position: relative;
}
</style>
