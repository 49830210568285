<template>
    <div class="profileChannels popoutWrapper">
        <div class="settingsTopbar">
            <router-link
                :to="`/settings/profile/${$route.params.id}/`"
                class="back flexContainer"
                ><svg
                    id="glyphicons-basic"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                >
                    <path
                        id="chevron-left"
                        d="M22.41406,23.37866a.5.5,0,0,1,0,.70709L19.586,26.91425a.50007.50007,0,0,1-.70715,0L8.67151,16.70709a.99988.99988,0,0,1,0-1.41418L18.87885,5.08575a.50007.50007,0,0,1,.70715,0l2.82806,2.8285a.5.5,0,0,1,0,.70709L15.03564,16Z"
                    /></svg
                >Tilbage</router-link
            >
            <h3 class="title">Kanal håndtering</h3>
        </div>
        <SettingCategory heading="Synlige kanaler">
            <TransitionGroup
                name="popoutList"
                tag="div"
                class="dragContainer"
                @dragover="dragOver"
            >
                <div
                    v-for="channel in channels"
                    :key="channel._id"
                    class="profileChannel settingItem"
                    draggable="true"
                    @dragstart="startDrag"
                    @dragend="endDrag"
                    :enabled="channel.enabled"
                    :value="channel._id"
                    :id="channel._id"
                >
                    <div class="flexContainer">
                        <button
                            class="removeChannel channelAction"
                            @click="
                                () => {
                                    removeChannel(channel._id);
                                }
                            "
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path
                                    d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"
                                />
                            </svg>
                        </button>
                        <p>{{ channel.baseChannel }} {{ channel.name }}</p>
                    </div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                    >
                        <path
                            d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
                        />
                    </svg>
                </div>
            </TransitionGroup>
        </SettingCategory>
        <SettingCategory heading="Tilgængelige kanaler">
            <TransitionGroup name="popoutList" tag="div">
                <div
                    v-for="channel in inactiveChannels"
                    :key="channel._id"
                    class="profileChannel settingItem"
                    :value="channel._id"
                >
                    <div class="flexContainer">
                        <button
                            class="addChannel channelAction"
                            @click="
                                () => {
                                    addChannel(channel._id);
                                }
                            "
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path
                                    d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                                />
                            </svg>
                        </button>
                        <p>{{ channel.baseChannel }} {{ channel.name }}</p>
                    </div>
                </div>
            </TransitionGroup>
        </SettingCategory>
    </div>
</template>

<script>
import axios from 'axios';
import SettingCategory from './SettingCategory.vue';
import message from '@/components/statusMessage';
export default {
    name: 'profileChannels',
    data() {
        return {
            channels: [],
            inactiveChannels: []
        };
    },
    components: {
        SettingCategory
    },
    methods: {
        getDragAfterElement(container, y) {
            const draggableElements = [
                ...container.querySelectorAll('.profileChannel:not(.dragging)')
            ];

            return draggableElements.reduce(
                (closest, child) => {
                    const box = child.getBoundingClientRect();
                    const offset = y - box.top - box.height / 2;
                    if (offset < 0 && offset > closest.offset) {
                        return { offset: offset, element: child };
                    } else {
                        return closest;
                    }
                },
                { offset: Number.NEGATIVE_INFINITY }
            ).element;
        },
        startDrag(e) {
            e.currentTarget.classList.add('dragging');
        },
        endDrag(e) {
            e.currentTarget.classList.remove('dragging');
            let channels = this.prepareChannels();
            this.$nextTick(function () {
                this.saveChannelList(channels);
            });
        },
        dragOver(e) {
            e.preventDefault();
            const afterElement = this.getDragAfterElement(
                e.currentTarget,
                e.clientY
            );
            const draggable = document.querySelector('.dragging');
            if (afterElement == null) {
                e.currentTarget.appendChild(draggable);
            } else {
                e.currentTarget.insertBefore(draggable, afterElement);
            }
        },
        prepareChannels() {
            let self = this;
            let channels = [...document.querySelectorAll('.profileChannel')];
            let userNewChannels = [];
            channels.forEach((channel) => {
                let id = channel.getAttribute('id');
                let enabled = channel.getAttribute('enabled');
                if (enabled == 'true') {
                    userNewChannels.push(
                        self.channels.find((o) => {
                            return o._id == id;
                        })
                    );
                }
            });
            return userNewChannels;
        },
        fetchChannels() {
            let self = this;
            let token = window.localStorage.getItem('token');
            let dataUrl = `${this.$store.state.apiUrl}channel?token=${token}`;
            axios
                .get(dataUrl)
                .then((res) => {
                    let channels = res.data;
                    let profileChannelUrl = `${self.$store.state.apiUrl}drmprofile/${self.$route.params.id}?token=${token}`;
                    axios
                        .get(profileChannelUrl)
                        .then((res) => {
                            let profileChannels = res.data?.channels;
                            let activeChannels = [];
                            let inactiveChannels = [];
                            channels.forEach((channel) => {
                                if (profileChannels.includes(channel._id)) {
                                    channel.enabled = true;
                                    activeChannels.push(channel);
                                } else {
                                    channel.enabled = false;
                                    inactiveChannels.push(channel);
                                }
                            });
                            activeChannels.sort(function (a, b) {
                                return (
                                    profileChannels.indexOf(a._id) -
                                    profileChannels.indexOf(b._id)
                                );
                            });
                            self.channels = activeChannels;
                            self.inactiveChannels = inactiveChannels;
                        })
                        .catch((err) => console.error(err));
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        removeChannel(channelId) {
            let self = this;
            let channel = this.channels.find((o) => {
                return o._id == channelId;
            });
            this.channels.splice(
                self.channels.findIndex((o) => {
                    return o._id == channelId;
                }),
                1
            );
            channel.enabled = false;
            this.inactiveChannels.push(channel);
            this.$nextTick(function () {
                this.saveChannelList(this.channels);
            });
        },
        saveChannelList(channels) {
            let token = window.localStorage.getItem('token');
            let channelIds = [];
            [...channels].forEach((channel) => {
                channelIds.push(channel._id);
            });
            let self = this;
            let body = {
                channels: channelIds
            };
            let dataUrl = `${this.$store.state.apiUrl}drmprofile/${this.$route.params.id}?token=${token}`;
            axios
                .post(dataUrl, body, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((res) => {
                    self.$store.state.user.profile = res.data;
                    message.Success('Profilen er gemt');
                })
                .catch((err) => {
                    console.error(err);
                    message.Error('Profilen kunne ikke gemmes');
                });
        },
        addChannel(channelId) {
            let self = this;
            let channel = this.inactiveChannels.find((o) => {
                return o._id == channelId;
            });
            this.inactiveChannels.splice(
                self.inactiveChannels.findIndex((o) => {
                    return o._id == channelId;
                }),
                1
            );
            channel.enabled = true;
            this.channels.push(channel);
            this.$nextTick(function () {
                this.saveChannelList(this.channels);
            });
        }
    },
    mounted() {
        this.fetchChannels();
    }
};
</script>

<style lang="scss" scoped>
@import '../components/variables.scss';
@import '@/components/popoutSettings.scss';
.profileChannels {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1.5fr 1fr;
    flex-direction: column;
    * {
        flex-wrap: nowrap;
        white-space: nowrap;
    }
    .dragContainer {
        .profileChannel {
            cursor: move;
        }
    }
    .flexContainer {
        p {
            margin-left: 10px;
        }
    }
    .profileChannel {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 17px;
        overflow: hidden;
        max-height: 75px;
        cursor: default;
        &:last-of-type {
            margin-bottom: unset;
        }
        &.dragging {
            color: rgb(var(--themeColor));
            background-color: rgb(var(--secondaryBackground-brighter));
        }
        .channelAction {
            height: 25px;
            width: 25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &.removeChannel {
                background-color: red;
            }
            &.addChannel {
                background-color: rgb(0, 205, 0);
            }
            svg {
                path {
                    fill: white !important;
                }
            }
        }
        svg {
            path {
                fill: rgb(var(--secondaryTextColor));
            }
        }
        &:hover {
            .channelAction {
                svg {
                    path {
                        fill: white;
                    }
                }
            }
        }
    }
    .listActions {
        text-align: right;
        .actionBtn {
            font-size: 15px;
            border-radius: 10px;
            line-height: 20px;
            padding: 10px 20px;
            text-align: center;
            background-color: rgb(0, 205, 0);
            cursor: pointer;
            text-decoration: none;
            font-weight: bold;
            &.cancel {
                background-color: red;
            }
            &:first-of-type {
                margin-right: 10px;
            }
        }
    }
    &.v-enter-from {
        width: 0;
        padding: 0;
        margin: 0;
    }
    &.v-leave-to {
        width: 0;
        padding: 0;
        margin: 0;
    }
}
</style>
