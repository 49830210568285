<template>
    <div class="settingCategory">
        <p class="categoryHeading">
            {{ heading }}
        </p>
        <div class="categoryContent">
            <slot />
        </div>
        <p class="categoryDescription">
            {{ description }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'SettingCategory',
    props: {
        heading: String,
        description: String
    }
};
</script>

<style lang="scss" scoped>
.settingCategory {
    text-align: left;
    margin-bottom: 30px;
    height: max-content;
    display: flex;
    flex-direction: column;
    &:last-of-type {
        margin-bottom: 0;
    }
    .categoryHeading {
        margin-bottom: 5px;
        margin-left: 5px;
    }
    .categoryContent {
        flex-grow: 1;
        overflow: scroll;
    }
    .categoryDescription {
        margin-top: 5px;
        margin-left: 5px;
    }
}
.profileChannels .settingCategory {
    height: 100%;
    overflow: hidden;
}
</style>
