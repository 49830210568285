<template>
    <div class="loadingIcon" :style="`padding: ${size / 3}px;`">
        <svg
            :style="`height: ${size}px; width: ${size}px`"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
        >
            <path
                d="M304 48c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zm0 416c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zM48 304c26.5 0 48-21.5 48-48s-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48zm464-48c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zM142.9 437c18.7-18.7 18.7-49.1 0-67.9s-49.1-18.7-67.9 0s-18.7 49.1 0 67.9s49.1 18.7 67.9 0zm0-294.2c18.7-18.7 18.7-49.1 0-67.9S93.7 56.2 75 75s-18.7 49.1 0 67.9s49.1 18.7 67.9 0zM369.1 437c18.7 18.7 49.1 18.7 67.9 0s18.7-49.1 0-67.9s-49.1-18.7-67.9 0s-18.7 49.1 0 67.9z"
            />
        </svg>
    </div>
</template>

<script>
export default {
    name: 'LoadingIcon',
    props: {
        size: Number
    }
};
</script>

<style lang="scss" scoped>
.loadingIcon {
    background-color: rgba(var(--primaryBackground), 0.5);
    display: flex;
    margin: 50px auto;
    border-radius: 50%;
    svg {
        animation: spinnerAnimation infinite forwards;
        animation-duration: 1s;
        animation-timing-function: linear;
        path {
            fill: rgb(var(--secondaryTextColor));
        }
    }
}
@keyframes spinnerAnimation {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
