<template>
    <Transition>
        <div class="playerMenu channelGuide" v-if="channels.length > 0">
            <div class="channelGrid">
                <ChannelCard
                    v-for="channel in channels"
                    :key="channel._id"
                    :channel="channel"
                    :currentChannel="currentChannel"
                    :onSelect="onSelect"
                />
            </div>
        </div>
    </Transition>
</template>

<script>
import axios from 'axios';
import ChannelCard from '@/components/ChannelCard.vue';
export default {
    name: 'ChannelGuide',
    props: {
        currentChannel: String,
        onSelect: Function
    },
    data() {
        return {
            channels: []
        };
    },
    components: {
        ChannelCard
    },
    methods: {},
    beforeMount() {
        let self = this;
        let localStorage = window.localStorage;
        let token = localStorage.getItem('token');
        let profile = localStorage.getItem('lastProfile');
        axios
            .get(
                this.$store.state.apiUrl +
                    'channel' +
                    `?token=${token}` +
                    `&epgMode=NOW` +
                    `&epgConpact=true` +
                    `&profile=${profile}`
            )
            .then((res) => {
                self.channels = res.data;
                self.$nextTick(function () {
                    let selectedChannel = document.querySelector(
                        '.channelCard.selected'
                    );
                    let channelGrid = document.querySelector('.channelGrid');
                    let topOffset = selectedChannel.offsetTop;
                    channelGrid.scrollTop = topOffset - 200;
                });
            })
            .catch((err) => {
                console.error(err);
                self.$store.dispatch('validateLogin');
            });
    },
    mounted() {}
};
</script>

<style lang="scss" scoped>
@import './variables.scss';
.channelGuide {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 40rem;
    .heading {
        position: absolute;
        z-index: 99;
    }
}
.channelGrid {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    width: 40rem;
    gap: 10px;
    position: relative;
    &::-webkit-scrollbar {
        display: none;
    }
}
</style>
