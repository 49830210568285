<template>
    <div class="programInfo playerMenu">
        <div class="poster">
            <div
                :class="`programLock ${programData.favorite ? 'locked' : ''}`"
                v-if="programMode == 'recording' || programData.recording"
                @click="
                    () => {
                        lockProgram();
                    }
                "
            >
                <svg
                    v-if="programData.favorite"
                    id="glyphicons-basic"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                >
                    <path
                        id="lock"
                        d="M25,12H24V9a5.00588,5.00588,0,0,0-5-5H13A5.00588,5.00588,0,0,0,8,9v3H7a2.00591,2.00591,0,0,0-2,2V26a2.00591,2.00591,0,0,0,2,2H25a2.00591,2.00591,0,0,0,2-2V14A2.00591,2.00591,0,0,0,25,12Zm-8,8.7226V23.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V20.7226a2,2,0,1,1,2,0ZM20,12H12V9a1.01348,1.01348,0,0,1,1-1h6a1.01348,1.01348,0,0,1,1,1Z"
                    />
                </svg>
                <svg
                    v-else
                    id="glyphicons-basic"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                >
                    <path
                        id="lock-open"
                        d="M25,12H24V5a5.00588,5.00588,0,0,0-5-5H13A5.00588,5.00588,0,0,0,8,5V7h4V5a1.01348,1.01348,0,0,1,1-1h6a1.01348,1.01348,0,0,1,1,1v7H7a2.00591,2.00591,0,0,0-2,2V26a2.00591,2.00591,0,0,0,2,2H25a2.00591,2.00591,0,0,0,2-2V14A2.00591,2.00591,0,0,0,25,12Zm-8,8.7226V23.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V20.7226a2,2,0,1,1,2,0Z"
                    />
                </svg>
            </div>
            <img
                class="posterImg"
                :src="
                    programData.imageIds?.[0]
                        ? `https://image.powernet.tv/${programData?.imageIds?.[0]}?width=400&height=250`
                        : null
                "
                alt=""
            />
            <ProgressBar
                class="progressBar"
                v-if="programData.position > 10"
                :customWidth="`${programData.position / programData.duration}`"
            />
        </div>
        <span style="display: inline"
            ><h1 class="programTitle">{{ programData.title }}</h1>
            <span
                class="programEpisode"
                v-if="
                    programData.series &&
                    programData.series.season &&
                    programData.series.episode
                "
                >S{{ programData.series.season }} E{{
                    programData.series.episode
                }}</span
            ></span
        >
        <p class="genres">
            <span
                class="genre"
                :key="index"
                v-for="(genre, index) in programData.genres"
                >{{
                    index + 1 >= programData.genres?.length
                        ? genre
                        : genre + ', '
                }}</span
            >
        </p>
        <p class="duration">{{ programData.duration }} min.</p>
        <div
            :class="{
                actionButtons: true,
                startOverHidden: !showStartOver(programData)
            }"
        >
            <button
                v-if="showStartOver(programData)"
                class="actionButton startOver"
                @click="
                    () => {
                        onPlayToggle(programData, null, true);
                    }
                "
            >
                Se forfra
            </button>
            <button
                v-if="playable()"
                :class="
                    playingId == programData._id && playing
                        ? 'actionButton play playing'
                        : 'actionButton play'
                "
                @click="
                    () => {
                        //eslint-disable-next-line
                        onPlayToggle(
                            !programData.start &&
                                programMode == 'streaming' &&
                                programData.epgList &&
                                programData.epgList.length > 0
                                ? {
                                      ...programData.epgList[0],
                                      position:
                                          programData.position > 10
                                              ? programData.position
                                              : 0
                                  }
                                : programData,
                            getPlayMode(programData)
                        );
                    }
                "
            >
                {{ getPlayingLabel(programData) }}</button
            ><button
                :class="`actionButton list ${
                    programData.watchList ? 'onList' : ''
                }`"
                @click="
                    () => {
                        handleList(
                            programData,
                            programData.watchList ? 'delete' : 'add'
                        );
                    }
                "
            >
                {{
                    programData.watchList ? '- Min liste' : '+ Min liste'
                }}</button
            ><button
                v-if="
                    mode != 'streaming' ||
                    (programData.epgList && programData.epgList.length > 0)
                "
                :class="{
                    actionButton: true,
                    record: true,
                    recording:
                        typeof programData.recording != 'undefined'
                            ? programData.recording
                            : mode == 'recording'
                            ? true
                            : false
                }"
                @click="
                    () => {
                        handleRecord(
                            typeof programData.recording != 'undefined'
                                ? programData.recording
                                    ? 'delete'
                                    : 'add'
                                : mode == 'recording'
                                ? 'delete'
                                : 'add'
                        );
                    }
                "
            >
                <svg
                    id="glyphicons-basic"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                >
                    <path
                        id="voicemail"
                        d="M23,10a5.98922,5.98922,0,0,0-5.18823,9H14.18823A5.995,5.995,0,1,0,9,22H23a6,6,0,0,0,0-12ZM9,19a3,3,0,1,1,3-3A3.00328,3.00328,0,0,1,9,19Zm14,0a3,3,0,1,1,3-3A3.00328,3.00328,0,0,1,23,19Z"
                    />
                </svg>
                {{
                    typeof programData.recording != 'undefined'
                        ? programData.recording
                            ? 'Annuller optagelse'
                            : 'Optag'
                        : mode == 'recording'
                        ? 'Annuller optagelse'
                        : 'Optag'
                }}
            </button>
        </div>
        <SettingCategory
            heading="Kanaler"
            :description="`Andre kanaler hvor du kan se ${programData.title}`"
            :style="{ margin: '20px 0' }"
            v-if="
                programData.epgList &&
                programData.epgList.length > 1 &&
                !playingId &&
                programMode != 'recording'
            "
        >
            <div class="services channels">
                <EpgListItem
                    v-for="service in programData.epgList.filter((o, index) => {
                        if (index != 0 || new Date(o.start) > new Date()) {
                            return o;
                        }
                    })"
                    :key="service._id"
                    :info="{
                        name: service.channelName,
                        logo: service.channelLogo,
                        start: service.start
                    }"
                    :disabled="new Date() < new Date(service.start)"
                    @click="
                        () => {
                            if (new Date() > new Date(service.start)) {
                                onPlayToggle(service._id, 'archive');
                            }
                        }
                    "
                />
            </div>
        </SettingCategory>
        <SettingCategory
            heading="Streaming tjenester"
            :description="`Andre tjenester hvor du kan se ${programData.title}`"
            :style="{ margin: '20px 0' }"
            v-if="
                programData.streams &&
                programData.streams.length > 0 &&
                !playingId
            "
        >
            <div class="services">
                <EpgListItem
                    :info="{
                        name: streamingService.name,
                        logo: streamingService.logo,
                        start: null
                    }"
                    v-for="streamingService in programData.streams"
                    :key="streamingService._id"
                    @click="
                        () => {
                            openLink(streamingService.url);
                        }
                    "
                />
            </div>
        </SettingCategory>
        <p class="description">{{ programData.description }}</p>
    </div>
</template>

<script>
import axios from 'axios';
import message from '@/components/statusMessage';
import SettingCategory from '@/components/SettingCategory.vue';
import EpgListItem from '@/components/EpgListItem.vue';
import ProgressBar from '@/components/ProgressBar.vue';
export default {
    name: 'ProgramInfo',
    props: {
        playingId: String,
        playing: Boolean,
        onPlayToggle: Function,
        onList: Function,
        programMode: String,
        programData: Object,
        onRecordEpgListItem: Function,
        onLock: Function
    },
    data() {
        return {
            playerPlaying: this.playing,
            mode: this.programMode
        };
    },

    components: {
        SettingCategory,
        EpgListItem,
        ProgressBar
    },
    mounted() {
        if (this.programData.epgList && this.programData.epgList.length > 0) {
            // this.correctEpgList();
        }
    },
    methods: {
        openLink(url) {
            window.open(url, '_blank');
        },
        correctEpgList() {
            let epgList = this.programData.epgList;
            let newChannelList = [];
            let channelList = [...this.$store.state.channelList];
            epgList.forEach((item) => {
                let channel = channelList.find((o) => {
                    return o.baseChannel == item.channelId;
                });
                newChannelList.push({ ...channel, ...item });
            });
            // this.programData.epgList = newChannelList;
        },
        getPlayMode(program) {
            if (this.programMode != 'streaming') {
                if (program.start && new Date() < new Date(program.start)) {
                    return 'archive';
                }
            }
            if (this.programMode == 'channel') {
                if (new Date() < new Date(program.end)) {
                    return 'channel';
                } else {
                    return 'archive';
                }
            }
            if (this.programMode == 'recording') {
                return 'recording';
            }
            if (this.programMode == 'streaming') {
                return 'archive';
            }
            if (this.programMode == 'archive') {
                return 'archive';
            }
        },
        handleList(program, mode) {
            let localStorage = window.localStorage;
            let token = localStorage.getItem('token');
            let profile = localStorage.getItem('lastProfile');
            let dataUrl = `${this.$store.state.apiUrl}watchlist/${profile}/${
                program.programId ? program.programId : program._id
            }?token=${token}`;
            if (mode == 'add') {
                axios
                    .post(
                        dataUrl,
                        {},
                        {
                            'Content-Type': 'application/json'
                        }
                    )
                    .then(() => {
                        this.onList(true);
                    })
                    .catch((err) => {
                        console.error(err);
                        message.Error(
                            'Kunne ikke tilføje programmet til din liste'
                        );
                    });
            } else if (mode == 'delete') {
                axios
                    .delete(dataUrl, {
                        'Content-Type': 'application/json'
                    })
                    .then(() => {
                        this.onList(false);
                    })
                    .catch((err) => {
                        console.error(err);
                        message.Error(
                            'Kunne ikke fjerne programmet fra din liste'
                        );
                    });
            }
        },
        getProfileForRecording(mode) {
            if (mode == 'delete' && this.programMode == 'recording') {
                return this.programData.profile
                    ? this.programData.profile._id
                    : null;
            } else {
                return this.$store.state.user.profile._id
                    ? this.$store.state.user.profile._id
                    : null;
            }
        },
        getPlayingLabel(program) {
            if (this.playingId == program._id) {
                if (this.playing) {
                    return 'Pause';
                } else {
                    if (program.position > 10) {
                        return 'Se videre';
                    } else {
                        return 'Afspil';
                    }
                }
            }
            if (program.position > 10) {
                return 'Se videre';
            } else {
                return 'Afspil';
            }
        },
        showStartOver(program) {
            if (program.position > 10 && this.mode != 'channel') {
                return true;
            }
            if (this.mode == 'channel') {
                return true;
            }
            return false;
        },
        lockProgram() {
            let lockState = this.programData.favorite;
            let token = window.localStorage.getItem('token');
            let profile = this.programData.profile
                ? this.programData.profile._id
                : null;
            let programId = this.programData._id;
            if (!token) return;
            let dataUrl = `${
                this.$store.state.apiUrl
            }recording/${programId}/favorite?token=${token}&mode=${!lockState}${
                profile ? `&profile=${profile}` : ''
            }`;
            axios
                .get(dataUrl)
                .then(() => {
                    this.onLock(!lockState);
                })
                .catch((err) => {
                    console.error(err);
                    message.Error('Kunne ikke låse optagelsen');
                });
        },
        handleRecord(mode) {
            let self = this;
            let localStorage = window.localStorage;
            let profile = this.getProfileForRecording(mode);
            let dataUrl = `${this.$store.state.apiUrl}recording/${
                this.programMode == 'streaming'
                    ? self.programData?.epgList?.[0]?._id
                    : self.programData._id
            }/${mode}?token=${localStorage.getItem('token')}${
                profile ? `&profile=${profile}` : ''
            }`;
            axios
                .get(dataUrl)
                .then(({ data }) => {
                    if (data.success == true) {
                        self.programData.recording =
                            mode == 'add' ? true : false;
                    }
                })
                .catch((err) => {
                    console.error(err);
                    message.Error('Kunne ikke starte optagelse');
                });
        },
        playable() {
            let p = this.programData;
            let bool = true;
            switch (this.programMode) {
                case 'channel':
                    if (new Date(p.start) > new Date()) {
                        bool = false;
                    }
                    break;
                case 'archive':
                    if (new Date(p.start) > new Date()) {
                        bool = false;
                        break;
                    }
                    if (
                        p.epgList &&
                        ![...p.epgList].filter((o) => {
                            return new Date(o.start) < new Date();
                        }).length
                    ) {
                        bool = false;
                    }
                    break;
                case 'streaming':
                    if (!p.epgList || p.epgList.length < 1) {
                        bool = false;
                    }
                    if (
                        p.epgList &&
                        !p.epgList.filter((o) => new Date(o.start) < new Date())
                            .length
                    ) {
                        bool = false;
                    }
                    break;
                case 'recording':
                    if (new Date(p.start) > new Date()) {
                        bool = false;
                    }
                    if (p.expire && new Date(p.expire) < new Date()) {
                        bool = false;
                    }
                    break;
            }

            return bool;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/components/variables.scss';
.programInfo {
    width: 30rem;
    top: 30px;
    right: 30px;
    bottom: 30px;
    background-color: rgba(var(--primaryBackground), 0.5);
    padding: 20px;
    border-radius: 20px;
    overflow: scroll;
    .poster {
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
        height: max-content;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        .programLock {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            background-color: rgba(var(--secondaryBackground), 0.5);
            border-radius: 10px;
            padding: 5px;
            width: 35px;
            height: 35px;
            &.locked {
                background-color: rgb(var(--themeColor));
                svg {
                    path {
                        fill: rgb(var(--primaryTextColor));
                    }
                }
            }
            svg {
                height: 100%;
                width: 100%;
                path {
                    fill: rgb(var(--themeColor));
                }
            }
        }
        .posterImg {
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        .progressBar {
            position: absolute;
            bottom: 10px;
            left: 20px;
            right: 20px;
            width: unset;
        }
    }
    .programTitle {
        display: inline;
    }
    .programEpisode {
        margin-left: 10px;
        color: rgb(var(--secondaryTextColor));
        font-size: 13px;
        font-weight: bold;
    }
    .actionButtons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'startOver play' 'list record';
        gap: 10px;
        width: 100%;
        flex-wrap: wrap;
        margin: 10px 0;
        &.startOverHidden {
            grid-template-areas: 'play play' 'list record';
        }
        button {
            height: 30px;
            border-radius: 5px;
            font-weight: bold;
            font-size: 14px;
            flex-shrink: 0;
            transition: background-color 0.1s linear, color 0.1s linear,
                box-shadow 0.1s ease;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            svg {
                height: 20px;
                width: 20px;
            }
            &:hover {
                box-shadow: $defaultShadow;
            }
            &.play {
                background-color: rgb(var(--themeColor));
                color: rgb(var(--primaryTextColor));
                width: 100%;
                grid-area: play;
                &.playing {
                    background-color: rgb(var(--secondaryBackground));
                    color: rgb(var(--themeColor));
                }
            }
            &.startOver {
                background-color: rgb(var(--themeColor));
                color: rgb(var(--primaryTextColor));
                grid-area: startOver;
            }
            &.list {
                background-color: rgb(var(--secondaryBackground));
                color: rgb(var(--themeColor));
                grid-area: list;
                &.onList {
                    background-color: rgb(var(--themeColor));
                    color: rgb(var(--primaryTextColor));
                }
            }
            &.record {
                background-color: rgb(var(--secondaryBackground));
                color: rgb(var(--themeColor));
                grid-area: record;
                svg {
                    path {
                        transition: fill 0.1s linear;
                        fill: rgb(var(--themeColor));
                    }
                }
                &.recording {
                    background-color: rgb(var(--themeColor));
                    color: rgb(var(--primaryTextColor));
                    svg {
                        path {
                            fill: rgb(var(--primaryTextColor));
                        }
                    }
                }
            }
        }
    }
    .services {
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
        &.channels {
            img.serviceLogo {
                object-fit: contain;
            }
        }
    }
}
</style>
