<template>
    <div class="userDevices popoutWrapper">
        <div class="settingsTopbar">
            <router-link to="/settings" class="back flexContainer"
                ><svg
                    id="glyphicons-basic"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                >
                    <path
                        id="chevron-left"
                        d="M22.41406,23.37866a.5.5,0,0,1,0,.70709L19.586,26.91425a.50007.50007,0,0,1-.70715,0L8.67151,16.70709a.99988.99988,0,0,1,0-1.41418L18.87885,5.08575a.50007.50007,0,0,1,.70715,0l2.82806,2.8285a.5.5,0,0,1,0,.70709L15.03564,16Z"
                    /></svg
                >Tilbage</router-link
            >
            <h3 class="title">Brugerenheder</h3>
        </div>
        <div class="devices">
            <TransitionGroup tag="div" name="popoutList">
                <DeviceCard
                    :device="device"
                    :index="index"
                    v-for="(device, index) in devices"
                    :key="device._id"
                    :onDelete="removeDeletedDevice"
                />
            </TransitionGroup>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import DeviceCard from '@/components/DeviceCard.vue';
export default {
    name: 'UserDevices',
    components: { DeviceCard },
    data() {
        return {
            devices: []
        };
    },
    beforeMount() {},
    mounted() {
        this.fetchDevices();
    },
    methods: {
        fetchDevices() {
            let self = this;
            let token = window.localStorage.getItem('token');
            let dataUrl = `${this.$store.state.apiUrl}device?token=${token}`;
            axios
                .get(dataUrl)
                .then((res) => {
                    self.devices = res.data;
                })
                .catch((err) => {
                    console.error(err);
                    self.$store.dispatch('validateLogin');
                });
        },
        removeDeletedDevice(deviceId) {
            this.devices = this.devices.filter((o) => {
                return o._id != deviceId;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/components/popoutSettings.scss';
.userDevices {
    padding: 10px 10px 10px 40px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    * {
        white-space: nowrap;
    }
    &.v-enter-from {
        width: 0;
        padding: 0;
        margin: 0;
    }
    &.v-leave-to {
        width: 0;
        padding: 0;
        margin: 0;
    }
    .devices {
        margin-top: 20px;
        height: 1px;
        flex-grow: 1;
        overflow: scroll;
    }
}
</style>
