<template>
    <div class="timeLine">
        <div
            class="outerWrapper"
            :key="patch.label"
            v-for="patch in patches"
            :style="{ width: patch.width }"
        >
            <p>
                {{ patch.label }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TimeLine',
    data() {
        return {
            patches: []
        };
    },
    props: {
        modifier: Number,
        startHour: Number,
        customFirst: Number
    },
    methods: {
        getMinDiff(startDate, endDate) {
            const msInMinute = 60 * 1000;

            return Math.round(Math.abs(endDate - startDate) / msInMinute);
        }
    },
    mounted() {
        let self = this;
        Array.from(Array(24).keys()).forEach((num, index) => {
            self.patches.push({
                width: '40rem',
                label: (index + 1 > 10 ? index : '0' + index) + ':00'
            });
        });
    }
};
</script>

<style lang="scss" scoped>
@import './variables.scss';
.timeLine {
    z-index: 11;
    display: flex;
    height: 50px;
    grid-area: timeline;
    position: sticky;
    top: 0;
    padding: 5px 15px;
    background-color: rgb(var(--primaryBackground));
    .outerWrapper {
        flex-shrink: 0;
        padding: 0 5px;
        p {
            flex-grow: 1;
            display: flex;
            align-items: center;
            height: 100%;
            border-radius: 5px;
            padding: 4px 10px;
            text-align: left;
            font-weight: bold;
            background-color: rgb(var(--secondaryBackground-brighter));
        }
        &:first-of-type {
            padding: 0 5px 0 0;
        }
    }
}
</style>
