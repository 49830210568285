<template>
    <div class="settings">
        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transition">
                <component :is="Component" />
            </transition>
        </router-view>
        <div class="settingsMenu">
            <div class="inner">
                <SettingCategory heading="Profiler" description="">
                    <router-link
                        :class="{
                            settingItem: true,
                            profileItem: true,
                            active: defaultProfile == profile._id
                        }"
                        v-for="profile in profiles"
                        :key="profile._id"
                        :to="`/settings/profile/${profile._id}/`"
                    >
                        <h3 class="settingTitle">
                            <svg
                                width="22px"
                                height="21px"
                                viewBox="0 0 22 21"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                            >
                                <title>profile</title>
                                <g
                                    id="Page-1"
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                >
                                    <path
                                        d="M10.9326568,10.1430664 C12.3955475,10.1430664 13.6670318,8.83056641 13.6670318,7.12158203 C13.6670318,5.43310547 12.3955475,4.18212891 10.9326568,4.18212891 C9.46976622,4.18212891 8.19828184,5.46044922 8.19828184,7.13525391 C8.19828184,8.83056641 9.46293028,10.1430664 10.9326568,10.1430664 Z M15.512735,16.4731445 C16.2441803,16.4731445 16.6816803,16.1313477 16.6816803,15.5639648 C16.6816803,13.800293 14.4736725,11.3666992 10.9258209,11.3666992 C7.38480528,11.3666992 5.17679747,13.800293 5.17679747,15.5639648 C5.17679747,16.1313477 5.61429747,16.4731445 6.34574278,16.4731445 L15.512735,16.4731445 Z"
                                        id="􀉪"
                                        fill="#FFFFFF"
                                        fill-rule="nonzero"
                                    ></path>
                                </g></svg
                            >{{ profile.name }}
                        </h3>
                        <div class="settingAction"></div>
                    </router-link>
                    <button
                        class="settingItem addProfile"
                        @click="createProfile"
                    >
                        <h3 class="settingTitle">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path
                                    d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                                /></svg
                            >Tilføj profil
                        </h3>
                    </button>
                </SettingCategory>
                <SettingCategory heading="Generelt" description="">
                    <router-link
                        class="settingItem accountSettings"
                        to="/settings"
                    >
                        <h3 class="settingTitle">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                            >
                                <path
                                    d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336c44.2 0 80-35.8 80-80s-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80z"
                                /></svg
                            ><span class="settingTitle"
                                >Konto indstillinger</span
                            >
                        </h3>
                        <div class="settingAction"></div>
                    </router-link>
                    <router-link class="settingItem" to="/logonbycode">
                        <span class="settingTitle">Godkend enhed</span>
                    </router-link>
                </SettingCategory>
                <SettingCategory
                    heading="Billedkvalitet"
                    description="Den valgte billedkvalitet gælder kun for denne enhed."
                >
                    <div
                        class="settingItem"
                        @click="
                            () => {
                                setStreamingQuality('low');
                            }
                        "
                    >
                        <h3 class="settingTitle">Lav</h3>
                        <svg
                            v-if="streamingQuality == 'low'"
                            id="glyphicons-basic"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 32 32"
                        >
                            <path
                                id="check"
                                d="M27.37592,9.70459l-14.151,15.97693a.99985.99985,0,0,1-1.47558.02356L4.59711,18.1322a.99992.99992,0,0,1-.05384-1.31128L5.495,15.63123a.99994.99994,0,0,1,1.22808-.26966L12,18,24.79724,7.09863a.99991.99991,0,0,1,1.35553.0542l1.1817,1.18164A1,1,0,0,1,27.37592,9.70459Z"
                            />
                        </svg>
                    </div>
                    <div
                        class="settingItem"
                        @click="
                            () => {
                                setStreamingQuality('med');
                            }
                        "
                    >
                        <h3 class="settingTitle">Mellem</h3>
                        <svg
                            v-if="streamingQuality == 'med'"
                            id="glyphicons-basic"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 32 32"
                        >
                            <path
                                id="check"
                                d="M27.37592,9.70459l-14.151,15.97693a.99985.99985,0,0,1-1.47558.02356L4.59711,18.1322a.99992.99992,0,0,1-.05384-1.31128L5.495,15.63123a.99994.99994,0,0,1,1.22808-.26966L12,18,24.79724,7.09863a.99991.99991,0,0,1,1.35553.0542l1.1817,1.18164A1,1,0,0,1,27.37592,9.70459Z"
                            />
                        </svg>
                    </div>
                    <div
                        class="settingItem"
                        @click="
                            () => {
                                setStreamingQuality('high');
                            }
                        "
                    >
                        <h3 class="settingTitle">Høj</h3>
                        <svg
                            v-if="streamingQuality == 'high'"
                            id="glyphicons-basic"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 32 32"
                        >
                            <path
                                id="check"
                                d="M27.37592,9.70459l-14.151,15.97693a.99985.99985,0,0,1-1.47558.02356L4.59711,18.1322a.99992.99992,0,0,1-.05384-1.31128L5.495,15.63123a.99994.99994,0,0,1,1.22808-.26966L12,18,24.79724,7.09863a.99991.99991,0,0,1,1.35553.0542l1.1817,1.18164A1,1,0,0,1,27.37592,9.70459Z"
                            />
                        </svg>
                    </div>
                </SettingCategory>
            </div>
        </div>
    </div>
</template>

<script>
import SettingCategory from '../components/SettingCategory.vue';
import axios from 'axios';
import emitter from 'tiny-emitter/instance';
export default {
    name: 'SettingsMain',
    components: {
        SettingCategory
    },
    data() {
        return {
            profiles: [],
            defaultProfile: window.localStorage.getItem('lastProfile'),
            streamingQuality: ''
        };
    },
    methods: {
        setStreamingQuality(quality) {
            let localStorage = window.localStorage;
            localStorage.setItem('streamingQuality', quality);
            this.streamingQuality = quality;
        },
        getProfiles() {
            let self = this;
            let token = window.localStorage.getItem('token');
            let dataUrl = `${this.$store.state.apiUrl}drmprofile?token=${token}`;
            axios
                .get(dataUrl)
                .then((res) => {
                    self.profiles = res.data;
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        createProfile() {
            let self = this;
            let token = window.localStorage.getItem('token');
            let dataUrl = `${this.$store.state.apiUrl}drmprofile?token=${token}`;
            axios
                .post(
                    dataUrl,
                    {
                        name: 'Ny profil'
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
                .then((res) => {
                    emitter.emit('profileChange');
                    self.$router.push({
                        path: `/settings/profile/${res.data._id}/`
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    },
    mounted() {
        let self = this;
        this.getProfiles();
        emitter.on('profileChange', function () {
            self.getProfiles();
        });
        let preQuality = window.localStorage.getItem('streamingQuality');
        if (preQuality) {
            this.streamingQuality = preQuality;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../components/variables.scss';
div.settings {
    display: grid;
    grid-template-columns: clamp(200px, 30vw, 500px) 1fr;
    grid-template-areas: 'settingsMenu settings';
    gap: 30px;
    padding: 20px 50px;
    flex-grow: 1;
    height: calc(100vh - 10vh);

    .settingsMenu {
        grid-area: settingsMenu;
        border-right: 1px solid grey;
        padding: 0 30px 0 0;
        overflow: hidden;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        .inner {
            height: 1px;
            overflow: scroll;
            flex-grow: 1;
        }
        .settingItem {
            &.addProfile {
                .settingTitle {
                    color: rgb(var(--themeColor));
                    svg {
                        height: 15px;
                        width: 15px;
                        margin-right: 5px;
                        path {
                            fill: rgb(var(--themeColor));
                        }
                    }
                }
                &:hover {
                    svg {
                        path {
                            fill: rgb(var(--themeColor)) !important;
                        }
                    }
                }
            }
            &.accountSettings {
                .settingTitle {
                    svg {
                        height: 15px;
                        width: 15px;
                        margin-right: 5px;
                    }
                }
                &:hover {
                    .settingTitle {
                        color: rgb(var(--primaryTextColor)) !important;
                        svg {
                            path {
                                fill: rgb(var(--primaryTextColor)) !important;
                            }
                        }
                    }
                }
            }

            &.router-link-active {
                background-color: rgb(var(--secondaryBackground));
                color: rgb(var(--secondaryTextColor));
                &.accountSettings {
                    background-color: rgb(var(--secondaryBackground-bright));

                    .settingTitle {
                        color: rgb(var(--secondaryTextColor));
                        svg {
                            path {
                                fill: rgb(var(--secondaryTextColor));
                            }
                        }
                    }
                }
                .settingTitle {
                    color: rgb(var(--secondaryTextColor)) !important;
                    svg {
                        path {
                            fill: rgb(var(--secondaryTextColor));
                        }
                    }
                }
                svg {
                    path {
                        fill: rgb(var(--secondaryTextColor)) !important;
                    }
                }
            }
            &.router-link-exact-active {
                background-color: rgb(var(--tertiaryBackground));
                color: rgb(var(--primaryTextColor));
                &.accountSettings {
                    background-color: rgb(var(--tertiaryBackground));

                    .settingTitle {
                        color: rgb(var(--secondaryTextColor));
                        svg {
                            path {
                                fill: rgb(var(--secondaryTextColor));
                            }
                        }
                    }
                }
                .settingTitle {
                    color: rgb(var(--primaryTextColor)) !important;
                    svg {
                        path {
                            fill: rgb(var(--primaryTextColor));
                        }
                    }
                }
                svg {
                    path {
                        fill: rgb(var(--primaryTextColor)) !important;
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss">
.settingsTopbar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    .back {
        color: rgb(var(--themeColor));
        text-decoration: none;
        font-weight: bold;
        svg {
            path {
                fill: rgb(var(--themeColor));
            }
        }
        .title {
            width: 100%;
        }
    }
    svg {
        height: 30px;
        width: 30px;
    }
}
.settingsTitle {
    text-align: left;
    line-height: normal;
}
.appWrapper {
    overflow: hidden;
}
</style>
