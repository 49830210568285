<template>
    <div class="profileStreaming popoutWrapper">
        <div class="settingsTopbar">
            <router-link
                :to="`/settings/profile/${$route.params.id}/`"
                class="back flexContainer"
                ><svg
                    id="glyphicons-basic"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                >
                    <path
                        id="chevron-left"
                        d="M22.41406,23.37866a.5.5,0,0,1,0,.70709L19.586,26.91425a.50007.50007,0,0,1-.70715,0L8.67151,16.70709a.99988.99988,0,0,1,0-1.41418L18.87885,5.08575a.50007.50007,0,0,1,.70715,0l2.82806,2.8285a.5.5,0,0,1,0,.70709L15.03564,16Z"
                    /></svg
                >Tilbage</router-link
            >
            <h3 class="title">Streamingtjenester</h3>
        </div>
        <SettingCategory heading="Synlige streamingtjenester">
            <TransitionGroup
                name="popoutList"
                tag="div"
                class="dragContainer"
                @dragover="dragOver"
            >
                <div
                    v-for="service in streamingServices"
                    :key="service.name"
                    class="profileService settingItem"
                    draggable="true"
                    @dragstart="startDrag"
                    @dragend="endDrag"
                    :enabled="service.enabled"
                    :value="service"
                    :id="service._id"
                >
                    <div class="flexContainer">
                        <button
                            class="remove action"
                            @click="
                                () => {
                                    removeService(service._id);
                                }
                            "
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path
                                    d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"
                                />
                            </svg>
                        </button>
                        <p>{{ service.name }}</p>
                    </div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                    >
                        <path
                            d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
                        />
                    </svg>
                </div>
            </TransitionGroup>
        </SettingCategory>
        <SettingCategory heading="Skjulte streamingtjenester">
            <TransitionGroup
                name="channelList"
                tag="div"
                class="dragContainer"
                @dragover="dragOver"
            >
                <div
                    v-for="service in inactiveStreamingServices"
                    :key="service.name"
                    class="profileService settingItem"
                    draggable="true"
                    @dragstart="startDrag"
                    @dragend="endDrag"
                    :enabled="service.enabled"
                    :value="service"
                    :id="service._id"
                >
                    <div class="flexContainer">
                        <button
                            class="add action"
                            @click="
                                () => {
                                    addService(service._id);
                                }
                            "
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path
                                    d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                                />
                            </svg>
                        </button>
                        <p>{{ service.name }}</p>
                    </div>
                </div>
            </TransitionGroup>
        </SettingCategory>
    </div>
</template>

<script>
import axios from 'axios';
import SettingCategory from './SettingCategory.vue';
import message from '@/components/statusMessage';
export default {
    name: 'profileStreaming',
    data() {
        return {
            streamingServices: [],
            inactiveStreamingServices: []
        };
    },
    components: {
        SettingCategory
    },
    methods: {
        fetchServices() {
            let self = this;
            let token = window.localStorage.getItem('token');
            let dataUrl = `${this.$store.state.apiUrl}drmprofile/${this.$route.params.id}?token=${token}`;
            axios
                .get(dataUrl)
                .then((res) => {
                    self.streamingServices = res.data.streamingServices.filter(
                        (o) => {
                            o.enabled = true;
                            return o;
                        }
                    );
                    self.inactiveStreamingServices =
                        res.data.availableStreamingServices.filter((o) => {
                            o.enabled = false;
                            return o;
                        });
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        prepareServices() {
            let channels = [...document.querySelectorAll('.profileService')];
            let userNewChannels = [];
            channels.forEach((channel) => {
                let id = channel.getAttribute('id');
                let enabled = channel.getAttribute('enabled');
                if (enabled == 'true') {
                    userNewChannels.push(
                        this.streamingServices.find((o) => {
                            return o._id == id;
                        })
                    );
                }
            });
            return userNewChannels;
        },
        saveStreaming(services) {
            let token = window.localStorage.getItem('token');
            let serviceIds = [];
            [...services].forEach((service) => {
                serviceIds.push(service._id);
            });
            let self = this;
            let body = {
                streamingServices: serviceIds
            };
            let dataUrl = `${this.$store.state.apiUrl}drmprofile/${this.$route.params.id}?token=${token}`;
            axios
                .post(dataUrl, body, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((res) => {
                    self.$store.state.user.profile = res.data;
                    message.Success('Profilen er gemt');
                })
                .catch((err) => {
                    console.error(err);
                    message.Error('Profilen kunne ikke gemmes');
                });
        },
        addService(serviceId) {
            let self = this;
            let service = this.inactiveStreamingServices.find((o) => {
                return o._id == serviceId;
            });
            this.inactiveStreamingServices.splice(
                self.inactiveStreamingServices.findIndex((o) => {
                    return o._id == serviceId;
                }),
                1
            );
            service.enabled = true;
            this.streamingServices.push(service);
            this.$nextTick(function () {
                this.saveStreaming(this.streamingServices);
            });
        },
        removeService(serviceId) {
            let self = this;
            let service = this.streamingServices.find((o) => {
                return o._id == serviceId;
            });
            this.streamingServices.splice(
                self.streamingServices.findIndex((o) => {
                    return o._id == serviceId;
                }),
                1
            );
            service.enabled = false;
            this.inactiveStreamingServices.push(service);
            this.$nextTick(function () {
                this.saveStreaming(this.streamingServices);
            });
        },
        getDragAfterElement(container, y) {
            const draggableElements = [
                ...container.querySelectorAll('.profileService:not(.dragging)')
            ];

            return draggableElements.reduce(
                (closest, child) => {
                    const box = child.getBoundingClientRect();
                    const offset = y - box.top - box.height / 2;
                    if (offset < 0 && offset > closest.offset) {
                        return { offset: offset, element: child };
                    } else {
                        return closest;
                    }
                },
                { offset: Number.NEGATIVE_INFINITY }
            ).element;
        },
        startDrag(e) {
            e.currentTarget.classList.add('dragging');
        },
        endDrag(e) {
            e.currentTarget.classList.remove('dragging');
            let services = this.prepareServices();
            this.$nextTick(function () {
                this.saveStreaming(services);
            });
        },
        dragOver(e) {
            e.preventDefault();
            const afterElement = this.getDragAfterElement(
                e.currentTarget,
                e.clientY
            );
            const draggable = document.querySelector('.dragging');
            if (afterElement == null) {
                e.currentTarget.appendChild(draggable);
            } else {
                e.currentTarget.insertBefore(draggable, afterElement);
            }
        }
    },
    mounted() {
        this.fetchServices();
    }
};
</script>

<style lang="scss" scoped>
@import './variables.scss';
@import '@/components/popoutSettings.scss';
.profileStreaming {
    overflow: hidden;
    background-color: rgb(var(--primaryBackground));
    padding: 10px 10px 10px 40px;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1.5fr 1fr;
    .profileService {
        overflow: hidden;
        max-height: 75px;
        &:hover {
            svg {
                path {
                    fill: white !important;
                }
            }
        }
        .action {
            height: 25px;
            width: 25px;
            margin-right: 10px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            svg {
                path {
                    fill: white;
                }
            }
            &.remove {
                background-color: red;
                color: rgb(var(--primaryTextColor));
            }
            &.add {
                background-color: rgb(0, 205, 0);
                color: rgb(var(--primaryTextColor));
            }
        }
    }
    * {
        flex-wrap: nowrap;
        white-space: nowrap;
    }
    &.v-enter-from {
        width: 0;
        padding: 0;
        margin: 0;
    }
    &.v-leave-to {
        width: 0;
        padding: 0;
        margin: 0;
    }
    .channelList-enter-active,
    .channelList-leave-active {
        transition: all 0.3s ease;
    }
    .channelList-enter-from,
    .channelList-leave-to {
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
}
</style>
