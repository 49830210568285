<template>
    <div class="simpleGuide playerMenu">
        <Transition v-if="channelData">
            <div class="simpleGuideGrid">
                <div
                    class="simpleGuideGroup"
                    v-for="period in channelData"
                    :key="period.date"
                >
                    <p class="groupTitle">
                        {{ correctGroupTitle(period.date) }}
                    </p>
                    <div
                        :class="`guideProgramWrapper ${
                            new Date(program.availableUntil) < new Date()
                                ? 'disabled'
                                : ''
                        }`"
                        :key="program._id"
                        v-for="program in period.programs"
                    >
                        <h2
                            :class="{
                                startTime: true,
                                selected: playingProgram._id == program._id
                            }"
                        >
                            {{
                                correctClockNum(
                                    new Date(program.start).getHours()
                                )
                            }}:{{
                                correctClockNum(
                                    new Date(program.start).getMinutes()
                                )
                            }}
                        </h2>
                        <div
                            :class="{
                                guideProgram: true,
                                selected: playingProgram._id == program._id
                            }"
                            @click="() => openProgram(program, channel)"
                        >
                            <div class="preview">
                                <img
                                    :src="`https://image.powernet.tv/${program.imageIds?.[0]}?width=120&height=100`"
                                    onerror="this.onerror=null; this.src='/img/placeholder_img.png'"
                                    alt="thumbnail"
                                    class="thumbnail"
                                />
                            </div>
                            <div class="info">
                                <h3 class="programTitle">
                                    {{ program.title }}
                                </h3>
                                <p class="genres">
                                    <span
                                        :key="genre"
                                        v-for="(genre, index) in program.genres"
                                        >{{
                                            index + 1 >= program.genres.length
                                                ? genre
                                                : genre + ', '
                                        }}</span
                                    >
                                </p>
                                <div class="footer">
                                    <p class="subTitle">
                                        {{ program.subTitle }}
                                    </p>
                                    <div class="markers">
                                        <svg
                                            class="listMarker"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            v-if="program.myList"
                                        >
                                            <path
                                                d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                                            />
                                        </svg>
                                        <svg
                                            class="recordMarker"
                                            v-if="program.recording"
                                            id="glyphicons-basic"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 32 32"
                                        >
                                            <path
                                                id="voicemail"
                                                d="M23,10a5.98922,5.98922,0,0,0-5.18823,9H14.18823A5.995,5.995,0,1,0,9,22H23a6,6,0,0,0,0-12ZM9,19a3,3,0,1,1,3-3A3.00328,3.00328,0,0,1,9,19Zm14,0a3,3,0,1,1,3-3A3.00328,3.00328,0,0,1,23,19Z"
                                            />
                                        </svg>
                                    </div>
                                    <ProgressBar
                                        v-if="false"
                                        :start="program.start"
                                        :end="program.end"
                                        :duration="program.duration"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
        <div
            class="loadingContainer"
            v-else
            style="margin: auto 40px auto auto"
        >
            <LoadingIcon :size="30" />
        </div>
    </div>
</template>

<script>
import ProgressBar from './ProgressBar.vue';
import axios from 'axios';
import LoadingIcon from '@/components/LoadingIcon.vue';
export default {
    data() {
        return {
            selectedDate: new Date(),
            channelData: null,
            firstDate: '',
            lastDate: '',
            prevHeight: 0,
            gridOffsetTop: 0
        };
    },
    name: 'SimpleGuide',
    props: {
        channel: String,
        onSelect: Function,
        playingProgram: Object
    },
    components: {
        ProgressBar,
        LoadingIcon
    },
    mounted() {
        let getPeriod = this.getPeriodForPrograms(new Date(), true);
        this.fetchPrograms(getPeriod);
    },
    methods: {
        getPeriodForPrograms(date, initial) {
            let today = new Date(date);
            if (initial) {
                today = new Date(today.setDate(today.getDate() - 6));
            }
            let endDate = new Date(date);
            endDate = new Date(endDate.setDate(endDate.getDate() + 1));
            today.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);
            return {
                from: `${today.getFullYear()}-${this.correctClockNum(
                    today.getMonth() + 1
                )}-${this.correctClockNum(today.getDate())}`,
                to: `${endDate.getFullYear()}-${this.correctClockNum(
                    endDate.getMonth() + 1
                )}-${this.correctClockNum(endDate.getDate())}`
            };
        },
        correctClockNum(number) {
            return number < 10 ? '0' + number : number;
        },
        fetchPrograms(period, next) {
            let self = this;
            let localStorage = window.localStorage;
            let token = localStorage.getItem('token');
            let profile = localStorage.getItem('lastProfile');
            let dataUrl = `${self.$store.state.apiUrl}channel?channel=${self.channel}&token=${token}&profile=${profile}&epgMode=PERIOD&epgFull=true&from=${period.from}&to=${period.to}`;

            axios
                .get(dataUrl)
                .then((res) => {
                    if (self.channelData) {
                        if (next) {
                            self.channelData.push({
                                date: self.correctDate(period.from),
                                programs: res.data[0].epg
                            });
                        }
                    } else {
                        self.channelData = this.constructDataArray(
                            period.from,
                            period.to,
                            res.data[0].epg
                        );
                    }
                    self.$nextTick(function () {
                        if (next) {
                            self.addObserver();
                        } else {
                            self.addObserver();
                            self.scrollToSelected();
                        }
                    });
                })
                .catch((err) => {
                    console.error(err);
                    self.$store.dispatch('validateLogin');
                });
        },
        scrollToSelected() {
            this.$nextTick(() => {
                let selectedProgram = document.querySelector(
                    '.guideProgram.selected'
                );
                let programGrid = document.querySelector(
                    '.playerMenu.simpleGuide'
                );
                if (selectedProgram) {
                    let topOffset = selectedProgram.offsetTop;
                    programGrid.scrollTop = topOffset - 200;
                } else {
                    programGrid.scrollTop = 0;
                }
            });
        },
        constructDataArray(from, to, data) {
            let fromDate = new Date(from);
            let toDate = new Date(to);
            let days =
                (toDate.getTime() - fromDate.getTime()) / (1000 * 3600 * 24);
            let constructedArray = [];
            for (let i = 0; i < days; i++) {
                let currentDate = new Date(
                    new Date(fromDate).setDate(fromDate.getDate() + i)
                );
                let objectToPush = {
                    date: currentDate.toISOString(),
                    programs: data.filter((o) => {
                        return (
                            new Date(o.start).setHours(0, 0, 0, 0) ==
                            new Date(currentDate).setHours(0, 0, 0, 0)
                        );
                    })
                };
                if (objectToPush.programs.length > 0) {
                    constructedArray.push(objectToPush);
                }
            }
            return constructedArray;
        },
        openProgram(program) {
            this.onSelect(program);
        },
        addObserver() {
            let self = this;
            let options = {
                root: document.querySelector('.playerMenu.simpleGuide'),
                rootMargin: '0px',
                threshold: 1
            };
            let groups = document.querySelectorAll('.simpleGuideGroup');
            let lastGroup = groups[groups.length - 1];
            let programs = lastGroup.querySelectorAll('.guideProgramWrapper');
            let lastProgram = programs[programs.length - 3];
            let lastObserver = new IntersectionObserver(function (e) {
                let entry = e[0];
                if (entry && entry.intersectionRatio > 0) {
                    self.fetchNextDay();
                    lastObserver.unobserve(lastProgram);
                }
            }, options);

            if (lastProgram) {
                lastObserver.observe(lastProgram);
            }
        },
        fetchNextDay() {
            let newPeriod = this.getPeriodForPrograms(
                this.lastDate ? this.lastDate : new Date()
            );

            this.lastDate = newPeriod.to;
            this.fetchPrograms(
                this.getPeriodForPrograms(newPeriod.to),
                true,
                false
            );
        },
        fetchPriorDay() {
            let newPeriod = this.getPeriodForPrograms(
                this.getDayBefore(this.firstDate ? this.firstDate : new Date())
            );

            this.firstDate = newPeriod.from;
            this.fetchPrograms(newPeriod, false, true);
        },
        correctDate(date) {
            let _date = new Date(date);
            return `${_date.getFullYear()}-${this.correctClockNum(
                _date.getMonth() + 1
            )}-${this.correctClockNum(_date.getDate())}`;
        },
        getDayBefore(date) {
            let cDate = new Date(date);
            cDate.setDate(cDate.getDate() - 1);
            cDate = new Date(cDate);
            return `${cDate.getFullYear()}-${this.correctClockNum(
                cDate.getMonth() + 1
            )}-${this.correctClockNum(cDate.getDate())}`;
        },
        dateIsToday(date, plusDays) {
            let today = new Date(new Date().setHours(0, 0, 0, 0));
            if (typeof plusDays != 'undefined') {
                today = new Date(
                    new Date(today).setDate(today.getDate() + plusDays)
                ).toISOString();
            } else {
                today = today.toISOString();
            }
            let _date = new Date(
                new Date(date).setHours(0, 0, 0, 0)
            ).toISOString();
            return today == _date ? true : false;
        },
        correctGroupTitle(date) {
            let _date = new Date(date);
            let day = new Date(_date).toLocaleString('default', {
                weekday: 'long'
            });
            let d = _date.getDate();
            let m = new Date(_date).toLocaleString('default', {
                month: 'long'
            });
            let y = _date.getFullYear();
            let defaultDate = `${day} den ${d}. ${m} ${y}`;
            if (this.dateIsToday(date)) {
                return 'I dag';
            } else if (this.dateIsToday(date, 1)) {
                return 'I morgen';
            } else if (this.dateIsToday(date, -1)) {
                return 'I går';
            } else {
                return defaultDate;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import './variables.scss';

div.simpleGuide {
    position: relative;
    z-index: 99;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 40rem;
    right: 20px;
    top: 0;
    bottom: 0;
    margin-left: auto;
    overflow-y: scroll;
    header {
        .heading {
            margin-bottom: 10px;
        }
        .topbar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            margin: 10px 20px;

            .channelLogo {
                width: 100px;
                height: 60px;
                object-fit: contain;
            }
        }
    }
    .simpleGuideGroup {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 10px 20px;
        .groupTitle {
            position: sticky;
            top: 20px;
            right: 20px;
            background-color: rgba(var(--primaryBackground), 0.8);
            color: rgb(var(--primaryTextColor));
            border-radius: 10px;
            width: 100%;
            padding: 10px 20px;
            z-index: 99;
            font-weight: 900;
            font-size: 25px;
            backdrop-filter: blur(10px);
        }
    }
    .guideProgramWrapper {
        display: flex;
        align-items: center;
        &:first-of-type {
            margin-top: 2rem;
        }
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
            .guideProgram {
                cursor: default;
                pointer-events: none;
            }
        }
        .startTime {
            color: rgb(var(--secondaryTextColor));
            margin-right: 20px;
            font-weight: 900;
            &.selected {
                color: rgb(var(--themeColor));
            }
        }
        .guideProgram {
            flex-shrink: 0;
            margin-bottom: 10px;
            background-color: rgba(var(--primaryBackground), 0.8);
            display: flex;
            height: 120px;
            border-radius: 15px;
            overflow: hidden;
            flex-grow: 1;
            width: min-content;
            cursor: pointer;
            transition: box-shadow 0.15s ease, transform 0.15s ease;
            &.selected {
                border: 3px solid rgb(var(--themeColor));
            }
            .footer {
                margin-top: auto;
                display: flex;
                .subTitle {
                    margin-right: auto;
                }
                svg {
                    height: 20px;
                    width: 20px;
                }
                .listMarker {
                    path {
                        fill: rgb(var(--themeColor));
                    }
                }
                .recordMarker {
                    path {
                        fill: red;
                    }
                }
            }
            &:hover {
                box-shadow: 0 0 3px 4px rgba(16, 16, 16, 0.64);
                transform: scale(1.05);
            }
            .preview {
                height: 100%;
                width: 33%;
                max-width: 150px;
                position: relative;
                flex-shrink: 0;
                .thumbnail {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .playIcon {
                    position: absolute;
                    bottom: 5px;
                    left: 5px;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    path {
                        fill: rgb(var(--themeColor));
                        box-shadow: 0 0 10px 10px black;
                    }
                }
            }
            .info {
                padding: 10px;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                height: 100%;
                .programTitle {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
                .genres {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
    .simpleGuideGrid {
        &.v-enter-from {
            opacity: 0;
        }
    }
}
</style>
